export const _enableDesinger = true //use this

// dont edit
export const designerEnabled = _enableDesinger && process.env.NODE_ENV === 'development'
export const baseUrl =
  process.env.NODE_ENV === 'development' && designerEnabled ? 'http://localhost:3001/' : '/'

export const getFileUrl = (url) => {
  return process.env.NODE_ENV === 'development' && designerEnabled
    ? baseUrl + 'file/' + encodeURIComponent(url)
    : baseUrl + url
}
// .split('\\') .join('/')
export const roomFormElements = {
  name: '',
  model: {},
  title: '',
  desc: '',
  suboptions: '[]',
}
export const hotspotFormElements = {
  urlToPush: '',
  name: '',
  title: '',
  desc: '',
  suboptions: '[]',
  type: '',
}
