export const extractParams = (searchStr) => {
  if (searchStr === null || searchStr === '') return {}
  let par = searchStr.slice(1, searchStr.length).split('&')
  let res = {}
  par.forEach((x) => (res[x.split('=')[0]] = x.split('=')[1]))
  Object.keys(res).forEach(
    // (x) => (res[x] = x !== 'page' && x !== 'location' ? Number(res[x]) : res[x])
    (x) => (res[x] = x !== 'page' && x !== 'location' ? res[x] : res[x])
  )
  return res
}

export const urlifyObject = (obj) => {
  let res = 'search?'
  Object.keys(obj).forEach((key) => {
    res += key + '=' + String(obj[key]) + '&'
  })
  return res.slice(0, res.length - 1)
}

export const getNewLocation = (oldLoc, newLoc) => {
  return oldLoc === '/' ? newLoc : newLoc[0] === '/' ? newLoc : oldLoc + '/' + newLoc
}
