import { StyleSheet, css } from 'aphrodite'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'wouter'
import { colors, font } from '../../theme'
import StandardBackwardArrow from '../assets/icons/StandardBackwardArrow'
import { hud } from '../../constants'

export default function Breadcrumbs() {
  const [location, setLocation] = useLocation()
  const [crumbsArray, setCrumbsArray] = useState(null)

  useEffect(() => {
    const temp = location.split('/')
    temp.shift()
    temp.forEach((element, index) => {
      temp[index] = hud[`/${element}`].title
    })
    setCrumbsArray(temp)
  }, [location])
  const popHistory = (idx) => {
    setLocation(
      '/' +
        location
          .split('/')
          .filter((x) => x !== '')
          .slice(0, idx + 1)
          .join('/')
    )
  }
  return (
    <>
      {crumbsArray !== null && (
        <div className={css(styles.container)}>
          {crumbsArray.map((crumbs, index) => {
            return (
              <div
                onClick={() => popHistory(index)}
                key={index}
                className={css(styles.crumbContainer)}
              >
                <StandardBackwardArrow />
                {/* <div className={css(styles.arrow)}></div> */}
                <div
                  className={css(styles.title)}
                  style={{
                    fontFamily: font.normal,
                    color: colors.trueBlue,
                    fontSize: '1.6vh',
                    lineHeight: '1.6vh',
                  }}
                >
                  <u>{crumbs}</u>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  crumbContainer: {
    height: '1.6vh',
    margin: '0.3vw 0.5vw 0vw 0vw',
    cursor: 'pointer',
    pointerEvents: 'auto',
    // backgroundColor: 'blue',
  },
  arrow: {
    float: 'left',
    // padding: '0 0.2vw 0 0',
    // height: '100%',
    // height: '1.6vh',
    lineHeight: '1.6vh',
    // transform: 'translateY(-50%)',
    backgroundColor: 'blue',
  },
  title: {
    float: 'right',
    height: '100%',
    marginLeft: '0.4vw',
  },
})
