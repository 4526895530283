import React, { useEffect } from 'react'
import * as THREE from 'three'
import { useLoadingManager } from '../stores'
const animationNames = {
  createrasp: 'CreateRASP',
  //   final: '',
  //   // timeli:'',
  review: 'ReviewPSUR',
  eu_glo: 'EU_Global',
  dismai: 'DistributionViaEmail',
  compur: 'CommPublishingReq',
  subrpu: 'SubmissionRequest',
  recpuo: 'ReceivePublishOutput',
  qcpuo: 'QCPublishOutput',
  adddoc: 'InsertAdditionalDocs',
  mudist: 'MUDistribution',
  psurtimeline: 'PSUR-Timeline',
}
function playAnimationForward(anim) {
  if (!anim) return
  console.log('animatoin name', anim)
  anim.loop = THREE.LoopRepeat
  anim.timeScale = 1
  anim.clampWhenFinished = true
  anim.paused = false
  anim.play()
}
export const ModelAnimations = ({ actions, name }) => {
  const getTitle = (name) => {}
  useEffect(() => {
    if (actions) {
      console.log('actions ', actions)
      let action = actions[animationNames[name]]
      // for (let actionName in actions) {
      //   playAnimationForward(actions[actionName])
      // }
      if (action) {
        playAnimationForward(action)
      }
    }
    return () => {
      if (actions && actions[animationNames[name]]) actions[animationNames[name]].paused = true
    }
  }, [name, actions])
  return <></>
}
