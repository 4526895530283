import React, { useEffect } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { animated as a } from '@react-spring/web'
import { useSpring } from '@react-spring/core'
import LoadingSpinner from '../../../panorama-viewer/context/ui/LoadingSpinner'
import { useLoadingManager } from '../../stores'
export const LoadingManager = () => {
  const loader = useLoadingManager()
  const { opacity } = useSpring({
    opacity: loader.fadeIn ? 1 : 0,
  })
  useEffect(() => {
    console.log('fading IN', loader.fadeIn)
  }, [loader.fadeIn])
  return (
    <a.div
      style={{ opacity, pointerEvents: loader.fadeIn ? 'auto' : 'none' }}
      className={css(styles.container)}
    >
      <div className={css(styles.spinner)}>
        <LoadingSpinner />
      </div>
    </a.div>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 200,
    backgroundColor: 'whitesmoke',
  },
  spinner: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },
})
