import React from 'react'

export const UnMuted = (props) => {
  return (
    <svg {...props} width="100%" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="24" fill="white" />
      <path
        d="M28.7135 19.463C29.9574 20.7069 30.6592 22.392 30.6662 24.1511C30.6731 25.9102 29.9845 27.6008 28.7505 28.8544"
        stroke="#001965"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M34.2064 16.6346C36.1966 18.6249 37.3196 21.321 37.3307 24.1356C37.3418 26.9502 36.2401 29.6551 34.2656 31.6609"
        stroke="#001965"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M12.2086 26.5742C11.2579 24.9897 11.2579 23.0103 12.2086 21.4258V21.4258C12.4987 20.9423 12.9793 20.6036 13.5322 20.493L15.7896 20.0416C15.9241 20.0147 16.0453 19.9425 16.1331 19.8372L19.8349 15.395C21.0175 13.9759 21.6087 13.2664 22.1364 13.4574C22.6641 13.6485 22.6641 14.5721 22.6641 16.4193L22.6641 31.5807C22.6641 33.4279 22.6641 34.3515 22.1364 34.5426C21.6087 34.7336 21.0175 34.0241 19.8349 32.605L16.1331 28.1628C16.0453 28.0575 15.9241 27.9853 15.7896 27.9584L13.5322 27.507C12.9793 27.3964 12.4987 27.0577 12.2086 26.5742V26.5742Z"
        stroke="#001965"
        stroke-width="1.5"
      />
    </svg>
  )
}

export const Muted = (props) => {
  return (
    <svg {...props} width="100%" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="24" fill="white" />
      <path
        d="M12.8765 26.5742C11.9259 24.9897 11.9259 23.0103 12.8765 21.4258V21.4258C13.1666 20.9423 13.6472 20.6036 14.2002 20.493L16.4575 20.0416C16.592 20.0147 16.7132 19.9425 16.801 19.8372L20.5029 15.395C21.6854 13.9759 22.2767 13.2664 22.8044 13.4574C23.332 13.6485 23.332 14.5721 23.332 16.4193L23.332 31.5807C23.332 33.4279 23.332 34.3515 22.8044 34.5426C22.2767 34.7336 21.6854 34.0241 20.5029 32.605L16.801 28.1628C16.7132 28.0575 16.592 27.9853 16.4575 27.9584L14.2002 27.507C13.6473 27.3964 13.1666 27.0577 12.8765 26.5742V26.5742Z"
        stroke="#001965"
        stroke-width="1.5"
      />
      <path d="M28.6641 28L36.6641 20" stroke="#001965" stroke-width="1.5" stroke-linecap="round" />
      <path d="M36.6641 28L28.6641 20" stroke="#001965" stroke-width="1.5" stroke-linecap="round" />
    </svg>
  )
}
