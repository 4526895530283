import { useTexture } from '@react-three/drei'
import React from 'react'
import { BackSide } from 'three'

export const Sky = () => {
  const texture = useTexture('/sky.png')
  return (
    <>
      <mesh>
        <sphereBufferGeometry args={[300, 60, 40]} />
        <meshBasicMaterial map={texture} side={BackSide} />
      </mesh>
    </>
  )
}
