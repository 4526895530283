import React, { useEffect, useRef, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { config, useSpring } from '@react-spring/core'
import { animated as a } from '@react-spring/web'
import { useAppState, useLoadingManager } from '../framework/3d-builder/stores'
export const VideoPlayer = ({ video }) => {
  const [mount, setMount] = useState(true)
  const { opacity } = useSpring({
    from: { opacity: 0 },
    opacity: mount ? 1 : 0,
    delay: !mount ? 1300 : 0,
    config: config.slow,
  })
  const ref = useRef()
  const { fadeIn } = useLoadingManager()
  const { setVideoPlaying } = useAppState()
  useEffect(() => {
    if (!fadeIn) {
      ref.current?.play()
      setTimeout(() => setVideoPlaying(true))
    }
  }, [fadeIn])
  useEffect(() => {
    return () => setVideoPlaying(false)
  }, [])
  return (
    <a.div
      onClick={() => ref.current?.play()}
      style={{ opacity, pointerEvents: mount ? 'auto' : 'none' }}
      className={css(styles.container)}
    >
      <video
        muted
        ref={ref}
        height="100%"
        width="100%"
        onEnded={() => {
          setMount(false)
          setVideoPlaying(false)
        }}
      >
        <source src={video} type="video/mp4" />
      </video>
    </a.div>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 599,
    textAlign: 'center',
    backgroundColor: 'black',
    // pointerEvents: 'none',
  },
})
