import React from 'react'

function ForwardArrow() {
  return (
    <svg height="3vh" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.94557 16.9531C13.3638 16.9531 16.9456 13.3714 16.9456 8.95312C16.9456 4.53485 13.3638 0.953125 8.94557 0.953125C4.69519 0.953125 1.219 4.2678 0.960938 8.4532H11.5349L9.60583 6.52411L10.3129 5.81701L13.0956 8.59965L13.4491 8.9532L13.0956 9.30675L10.3129 12.0894L9.60583 11.3823L11.5349 9.4532H0.960947C1.21908 13.6385 4.69524 16.9531 8.94557 16.9531Z"
        fill="white"
      />
    </svg>
  )
}

export default ForwardArrow
