import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, css } from 'aphrodite'
import { globalStyles } from '../../framework/panorama-viewer/context/GlobalStyles'
import Branding from './Branding'
import StandardHeaderWithDescription from './StandardHeaderWithDescription'
import Breadcrumbs from './Breadcrumbs'
import CenterButtonWithInformation from './CenterButtonWithInformation'

const alignment = 'left'

export default function CardType3({ visibility, cardObject }) {
  return (
    <div className={css(visibility ? styles.container : globalStyles.norender)}>
      <div className={css(styles.brandingContainer)}>
        <Branding />
      </div>
      <div className={css(styles.breadcrumbsContainer)}>
        <Breadcrumbs />
      </div>
      <div className={css(styles.scrollCard)}>
        <div className={css(styles.headerWithDescriptionContainer)}>
          <StandardHeaderWithDescription
            align={alignment}
            header={cardObject.header}
            descriptionFontSize={14}
            descriptionLineHeight="16px"
          />
        </div>
        <div className={css(styles.centerButtonContainer)}>
          <CenterButtonWithInformation
            align={'center'}
            buttonObject={cardObject.button}
            buttonWidth={'13.8vw'}
            buttonHeight={'auto'}
          />
        </div>
      </div>
    </div>
  )
}

CardType3.propTypes = {
  visibility: PropTypes.bool,
  cardObject: PropTypes.object,
}

const styles = StyleSheet.create({
  container: {
    // height: '100vh',
    // display: 'block',
  },
  brandingContainer: {
    height: '13.5vh',
    padding: '1.5vw',
  },
  breadcrumbsContainer: {
    height: '5.5vh',
    paddingLeft: '1.5vw',
    marginBottom: '1.5vh'
  },
  headerWithDescriptionContainer: {
    // height: '41vh',
    padding: '0vw 1.5vw',
    fontSize: '28px',
    lineHeight: '32.2px',
    '@media(max-width:800px)': {
      fontSize: '20px',
      lineHeight: '20px',
    }
  },
  centerButtonContainer: {
    height: '40vh',
    padding: '1.5vw',
  },
  scrollCard: {
    overflowY: 'auto',
    pointerEvents: 'auto',
    height: '80vh',
    '::-webkit-scrollbar': {
      width: '0.4rem',
      margin: '4.8vh',
      background: 'rgba(61, 61, 61, 0.8)',
      borderRadius: '10px',
    },
    '::-webkit-scrollbar-thumb': {
      background: 'rgba(242, 242, 242, 0.15)',
      borderRadius: '20px',
      height: '2.4vh',
    },
  }
})