export const logEnabled = false

export const shadowResolutions = {
  psur: 14,
  valuechain: 14,
  base: 14,
} // 2^shadowRes

export const gaConfig = {
  enabled: true,
  code: 'G-KMR3T0JGSM',
}

export const content = {
  '/': {
    title: 'Box 01',
    description: 'Box 01 is selected',
  },
  '/box2': {
    title: 'Box 02',
    description: 'Box 02 is selected',
  },
  '/box3': {
    title: 'Box 03',
    description: 'Box 03 is selected',
  },
}

// Tween points
export const TweenPoints = {
  camPosition: {
    position: [-10, 10, -15],
    control: [0, 0, 0],
    name: 'cubePositionName2',
  },
}

export const hud = {
  default: {
    cardType: -1,
    background: '#ffffff',
    header: {
      title: 'Lorem Ipsum',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sollicitudin nibh sit amet commodo nulla facilisi nullam vehicula.',
    },
    button: [
      {
        title: `Let's Get Started!`,
        target: '',
        defination: 'Click below to explore!',
      },
      {
        title: `Next`,
        target: '',
        defination: 'Click below to explore the city and see what Novo Nordisk is all about!',
      },
    ],
    list: [
      {
        title: 'Cras justo odio',
        target: 'cjo',
      },
      {
        title: 'Dapibus ac facilisis in',
        target: '',
      },
      {
        title: 'Morbi leo risus',
        target: '',
      },
      {
        title: 'Porta ac consectetur ac',
        target: '',
      },
      {
        title: 'Vestibulum at eros',
        target: '',
      },
    ],
    enabledItem: {
      index: 2,
    },
    voiceOver: {
      index: -1,
    },
  },
  '/': {
    cardType: -1,
    background: '#ffffff',
    header: {
      title: `Welcome to\nNovoNordisk Development City`,
      description:
        'Welcome to Novo-Nordisk Development City.  Click below to kickstart an immersive, interactive journey outlining all the phenomenal work we do here at Novo Nordisk Development. ',
    },
    button: [
      {
        title: `Let's Get Started!`,
        target: 'nndc',
        defination: '',
      },
    ],
    voiceOver: {
      index: 0,
    },
  },
  '/nndc': {
    cardType: 1,
    background: '#FFFFFFBF',
    title: 'Development City',
    header: {
      title: `Novo Nordisk Development City`,
      description:
        'At Novo Nordisk Development, we develop innovative medicines that improve lives for people with serious chronic diseases, and we aspire to be the best in the world at doing this.\nClick on a hotspot in the city, or click on any of the departments below to learn more about our work and impact we make.',
    },
    // breadcrumbs: [{ title: '', target: '' }],
    list: [
      {
        title: 'Clinical Drug Development',
        target: 'cdd',
      },
      {
        title: 'Global Chief Medical Office',
        target: 'gcmo',
      },
      {
        title: 'Submissions & Life Cycle Management',
        target: 'slcm',
      },
      {
        title: 'Data Science',
        target: 'ds',
      },
      {
        title: 'Device & Delivery Solutions',
        target: 'dds',
      },
    ],
    voiceOver: {
      index: 1,
    },
  },
  '/slcm': {
    cardType: 2,
    background: '#FFFFFFBF',
    title: 'Submissions & Life Cycle Management',
    header: {
      title: `Submissions & Life Cycle Management`,
      description:
        'SubLCM is at the heart of translating the science from our research candidates and our development activities into patient impact. We serve as the bridge between Research & Early Development, Development and the Commercial organisation.\n\nWe lead the right level of quality across R&D. We play a vital role in communicating the results of Novo Nordisk’s drug development programs. We secure the NN regulatory licenses to operate, enable competitive labels and impact regulatory frameworks.\n\nWe safeguard the patients through case handling, surveillance of adverse events and technical complaints, as well as handle actual recalls and fight counterfeit.​',
    },

    // breadcrumbs: [{ title: '', target: '' }],
    list: [
      {
        title: 'Clinical Reporting',
        target: 'cr',
      },
      {
        title: 'R & D Quality',
        target: 'rdq',
      },
      {
        title: 'Global Regulatory Affairs',
        target: 'gra',
      },
      {
        title: 'Global Safety',
        target: 'gs',
      },
    ],
    enabledItem: {
      index: 2,
    },
    voiceOver: {
      index: 2,
    },
  },

  '/cdd': {
    cardType: 2,
    background: '#FFFFFFBF',
    title: 'Clinical Drug Development',
    header: {
      title: `Clinical Drug Development`,
      description: 'Coming soon!',
    },

    // breadcrumbs: [{ title: '', target: '' }],
    list: [],
    enabledItem: {
      index: 2,
    },
    voiceOver: {
      index: -1,
    },
  },

  '/ds': {
    cardType: 2,
    background: '#FFFFFFBF',
    title: 'Data Science',
    header: {
      title: `Data Science`,
      description: 'Coming soon!',
    },

    // breadcrumbs: [{ title: '', target: '' }],
    list: [
      {
        title: '',
      },
      {
        title: '',
      },
      {
        title: '',
        target: '',
      },
      {
        title: '',
      },
    ],
    enabledItem: {
      index: 2,
    },
    voiceOver: {
      index: -1,
    },
  },

  '/gcmo': {
    cardType: 2,
    background: '#FFFFFFBF',
    title: 'Global Chief Medical Office',
    header: {
      title: `Global Chief Medical Office`,
      description: 'Coming soon!',
    },

    // breadcrumbs: [{ title: '', target: '' }],
    list: [
      {
        title: '',
      },
      {
        title: '',
      },
      {
        title: '',
        target: '',
      },
      {
        title: '',
      },
    ],
    enabledItem: {
      index: 2,
    },
    voiceOver: {
      index: -1,
    },
  },

  '/dds': {
    cardType: 2,
    background: '#FFFFFFBF',
    title: 'Device and Delivery Service',
    header: {
      title: `Device and Delivery Service`,
      description: 'Coming soon!',
    },

    // breadcrumbs: [{ title: '', target: '' }],
    list: [
      {
        title: '',
      },
      {
        title: '',
      },
      {
        title: '',
        target: '',
      },
      {
        title: '',
      },
    ],
    enabledItem: {
      index: 2,
    },
    voiceOver: {
      index: -1,
    },
  },

  '/gra': {
    cardType: 2,
    background: '#FFFFFFBF',
    title: 'Global Regulatory Affairs',
    header: {
      title: `Global Regulatory Affairs`,
      description:
        'In Global Regulatory Affairs, we are committed to ensuring global access to our products for patients. An important part of our job is to act as a bridge between Novo Nordisk and health authorities, enabling Novo Nordisk to stay one step ahead.\n\nWe prepare and execute regulatory strategies, ensure approval of new drugs and devices and expand availability of existing products by label expansions and by ensuring approval of Novo Nordisk manufacturing network.\n\nFor both new products and life-cycle management of products on the market, we develop the regulatory strategy assessing the current regulatory framework, anticipate future changes and shape future regulatory frameworks through dialogues and negotiations with health authorities and policymakers. ​',
    },
    // breadcrumbs: [{ title: '', target: '' }],
    list: [
      {
        title: 'RA CMC & Device',
        target: 'racd',
      },
      {
        title: 'RA Diabetes & Obesity, Novo Nordisk Rare D',
        target: '/valuechain/radb',
        // target: '/valuechain',
      },
      {
        title: 'RA Submssion & Labelling',
        target: 'rasl',
      },
    ],
    enabledItem: {
      index: 1,
    },
    voiceOver: {
      index: 3,
    },
  },
  '/cr': {
    cardType: 2,
    background: '#FFFFFFBF',
    title: 'Clinical Reporting',
    header: {
      title: `Clinical Reporting`,
      description: 'Coming soon!​',
    },
    // breadcrumbs: [{ title: '', target: '' }],
    list: [],
    enabledItem: {
      index: 1,
    },
    voiceOver: {
      index: -1,
    },
  },
  '/gs': {
    cardType: 2,
    background: '#FFFFFFBF',
    title: 'Global Safety',
    header: {
      title: `Global Safety`,
      description: 'Coming soon!​',
    },
    // breadcrumbs: [{ title: '', target: '' }],
    list: [],
    enabledItem: {
      index: 1,
    },
    voiceOver: {
      index: -1,
    },
  },
  '/rdq': {
    cardType: 2,
    background: '#FFFFFFBF',
    title: 'R&D Quality',
    header: {
      title: `R&D Quality`,
      description: 'Coming soon!​',
    },
    // breadcrumbs: [{ title: '', target: '' }],
    list: [],
    enabledItem: {
      index: 1,
    },
    voiceOver: {
      index: -1,
    },
  },

  '/radb': {
    cardType: 2,
    background: '#FFFFFFBF',
    noBreadCrumbs: true,
    title: 'RA Diabetes & Obesity, Novo Nordisk Rare D Post Marketing LCM',
    header: {
      title: `RA Diabetes & Obesity, Novo Nordisk Rare D Post Marketing LCM`,
      description:
        'RA Diabetes & Obesity, Novo Nordisk Rare Diseases has the overall responsibility for all global regulatory activities during drug development, submission and worldwide regulatory approval for applications for our therapeutic projects.​\n\nWe obtain approvals and reach our goals through close dialogue with regulatory authorities worldwide and close collaboration with internal stakeholders both in headquarters and affiliates continuously maximizing the value of product for the patients and for the business.\n\nWe are responsible for Developing robust global regulatory strategies across all projects and products within its portfolio, and for delivering marketing authorizations licenses in an expedited manner with the most competitive labels worldwide.',
    },
    // breadcrumbs: [{ title: '', target: '' }],
    list: [
      {
        title: 'NDA',
        target: '',
      },
      {
        title: 'PSUR',
        target: '/psur',
      },
      {
        title: 'Post Approval',
        target: '',
      },
      {
        title: 'Q&A From Health Authority',
        target: '',
      },
      {
        title: 'RMP',
        target: '',
      },
      {
        title: 'Investigator Brochure',
        target: '',
      },
      {
        title: 'DSUR',
        target: '',
      },
      {
        title: 'PIP',
        target: '',
      },
      {
        title: 'Annual Report',
        target: '',
      },
      {
        title: 'Clinical Trial Applications',
        target: '',
      },
      {
        title: 'Commitments',
        target: '',
      },
    ],
    enabledItem: {
      index: 'all',
    },
    voiceOver: {
      index: 4,
    },
  },
  '/valuechain': {
    cardType: 2,
    noBreadCrumbs: true,
    background: '#FFFFFFBF',
    title: 'The Value Chain',
    header: {
      title: `The Value Chain`,
      description:
        'It takes between 10-15 years to develop and market a new medicinal product, there are several steps that need to be ensured before a product can reach safely from an idea to the patients.',
    },
    // breadcrumbs: [{ title: '', target: '' }],
    list: [
      {
        title: 'Idea',
        target: 'idea',
      },
      {
        title: 'Early R&D',
        target: 'earlyrd',
      },
      {
        title: 'Product Registration',
        target: 'productreg',
      },
      {
        title: 'Product Launch',
        target: 'productlaunch',
      },
      {
        title: 'Post Marketing LCM',
        target: 'postmarklcm',
      },
      {
        title: 'De-registered',
        target: 'deregistered',
      },
    ],
    enabledItem: {
      index: 'all',
    },
    voiceOver: {
      index: 4,
    },
  },
  '/rasl': {
    cardType: 2,
    background: '#FFFFFFBF',
    title: 'RA Submission & Labelling',
    header: {
      title: `RA Submission & Labelling`,
      description: 'Coming soon!',
    },
    // breadcrumbs: [{ title: '', target: '' }],
    list: [],
    enabledItem: {
      index: 2,
    },
    voiceOver: {
      index: -1,
    },
  },
  '/racd': {
    cardType: 2,
    background: '#FFFFFFBF',
    title: 'RA CMC & Device',
    header: {
      title: `RA CMC & Device`,
      description: 'Coming soon!',
    },
    // breadcrumbs: [{ title: '', target: '' }],
    list: [],
    enabledItem: {
      index: 2,
    },
    voiceOver: {
      index: -1,
    },
  },

  '/psur': {
    cardType: 3,
    background: '#FFFFFFBF',
    render: 'renders/grandpa.png',
    title: 'PSUR',
    header: {
      title: `PSUR`,
      description:
        'Regulatory Affairs act as a bridge between the Health Authorities and our company  to ensure that our products can help millions of patients around the world.By the time the products reach the hands of our patients we would have ensured that the product is safe and effective to use. This we ensure with every task, small or large, we perform in Regulatory Affairs. We change the life of people living with chronic diseases.',
    },
    // breadcrumbs: [{ title: '', target: '' }],
    button: [
      {
        title: `Let’s Begin!`,
        target: 'psuroverview',
        defination: 'Click below to learn about PSUR',
      },
    ],
    voiceOver: {
      index: 5,
    },
  },
  '/psuroverview': {
    cardType: 3,
    background: '#FFFFFFBF',
    title: 'PSUR Overview',
    header: {
      title: `PSUR Overview`,
      description:
        'PSURs are pharmacovigilance documents intended to provide an evaluation of the risk-benefit balance of a medicinal product at defined time points after its authorisation.\n\nThe objective of the PSUR is to present a comprehensive and critical analysis of the risk-benefit balance of the product, taking into account new or emerging safety information in the context of cumulative information on risk and benefits.\n\nEMA and national competent authorities assess information in PSURs to determine if there are new risks identified for a medicine and/or if its risk-benefit balance has changed. ',
    },
    // breadcrumbs: [{ title: '', target: '' }],
    button: [
      {
        title: `Let’s Begin!`,
        target: 'psurtimeline',
        defination: 'Click below to learn about PSUR',
      },
    ],
    voiceOver: {
      index: 5,
    },
  },
  '/pacv': {
    cardType: 3,
    background: '#FFFFFFBF',
    title: 'Post Approval Changes/Variations',
    header: {
      title: `Post Approval Changes/Variations`,
      description: 'Coming soon!',
    },
    // breadcrumbs: [{ title: '', target: '' }],
    button: [
      {
        title: ``,
        target: '',
        defination: '',
      },
    ],
    voiceOver: {
      index: -1,
    },
  },
  '/aco': {
    cardType: 3,
    background: '#FFFFFFBF',
    title: 'Addendum to Clinical Overview',
    header: {
      title: `Addendum to Clinical Overview`,
      description: 'Coming soon!',
    },
    // breadcrumbs: [{ title: '', target: '' }],
    button: [
      {
        title: ``,
        target: '',
        defination: '',
      },
    ],
    voiceOver: {
      index: -1,
    },
  },
  '/pmr': {
    cardType: 3,
    background: '#FFFFFFBF',
    title: 'Promotional Material Review',
    header: {
      title: `Promotional Material Review`,
      description: 'Coming soon!',
    },
    // breadcrumbs: [{ title: '', target: '' }],
    button: [
      {
        title: ``,
        target: 'pmr',
        defination: '',
      },
    ],
    voiceOver: {
      index: -1,
    },
  },

  '/psurtimeline': {
    cardType: 4,
    background: '#FFFFFFBF',
    title: 'PSUR Timeline',
    header: {
      title: `PSUR Timeline`,
      description:
        'The PSUR framework varies based on the regulatory authority that you have to deal with. Whereas the EMA follows a 1-3-5 year reporting cycle based on the EURD List, at a Global level the PSUR is updated once annually.\n\nHowever, as a process, the first few steps remain the same across both frameworks.',
    },
    // breadcrumbs: [{ title: '', target: '' }],
    button: [
      {
        title: `Create RASP`,
        target: 'createrasp',
      },
    ],
    voiceOver: {
      index: 6,
    },
  },
  '/createrasp': {
    cardType: 5,
    background: '#FFFFFFBF',
    title: 'Create RASP',
    header: {
      title: `Create RASP`,
      description:
        'To kick things off, you will have to create a Regualtory Affairs Submission Platform(RASP) in order to alert the respective SMW, who will in turn pull the report and set the ball rolling with the working group.',
    },
    // breadcrumbs: [{ title: '', target: '' }],
    button: [
      {
        title: `PSUR Timeline`,
        target: 'psurtimeline',
      },
      {
        title: `Review PSUR`,
        target: 'review',
      },
    ],
    voiceOver: {
      index: 7,
    },
  },
  '/review': {
    cardType: 5,
    background: '#FFFFFFBF',
    title: 'Review RASP',
    header: {
      title: `Review RASP`,
      description:
        'At the end of the day, the Safety Medical Writing is responsible for handling the PSUR, and will lead the process of internal review and approval, which will be decided upon jointly, by the working group and management.',
    },
    // breadcrumbs: [{ title: '', target: '' }],
    button: [
      {
        title: `Create RASP`,
        target: 'createrasp',
      },
      {
        title: `EU/Global`,
        target: 'eu_glo',
      },
    ],
    voiceOver: {
      index: 7,
    },
  },
  '/eu_glo': {
    cardType: 2,
    background: '#FFFFFFBF',
    title: 'EU/Global',
    header: {
      title: `EU/Global`,
      description:
        'It is here that the divergence in process begins, between the EMA & other Global health authorities.',
    },
    // breadcrumbs: [{ title: '', target: '' }],
    list: [
      {
        title: 'Global',
        target: 'dismai',
      },
      {
        title: 'EU',
        target: 'compur',
      },
    ],
    enabledItem: {
      //wtf is this
      index: 'all',
    },
    voiceOver: {
      index: 4,
    },
  },
  '/dismai': {
    cardType: 5,
    background: '#FFFFFFBF',
    title: 'Distribution via email',
    header: {
      title: `Distribution via email`,
      description:
        'The Global framework requires the change-package to be created in VV (that is, the Veeva Vault), which includes the PSUR main-report, line-listings and country-specific appendices. \n\nThe PSUR is then distributed to the requisite countries, by creating the submissions in the country-specific applications.\n\nIn case, the PSUR has to be distributed to more than 20 countries, the distribution is via Vault Loader. You will fill the Vault Loader template and raise a Service Now ticket for the distribution.\n\nThe Global setup is comparatively straightforward as affiliates will submit the PSUR to their local Health authorities. ',
    },
    // breadcrumbs: [{ title: '', target: '' }],
    button: [
      {
        title: `EU/Global`,
        target: 'eu_glo',
      },
      {
        title: `MU Distribution`,
        target: 'mudist',
      },
    ],
    voiceOver: {
      index: 7,
    },
  },
  '/mudist': {
    cardType: 5,
    background: '#FFFFFFBF',
    title: 'MU Distribution',
    header: {
      title: `MU Distribution`,
      description:
        'Finally, once this process is complete, the Master Update Form is sent to all the relevant countries so that they can update their registrations!',
    },
    // breadcrumbs: [{ title: '', target: '' }],
    button: [
      {
        title: `Distribution via email`,
        target: 'dismai',
      },
      {
        title: `PSUR Impact`,
        target: 'final',
      },
    ],
    voiceOver: {
      index: 7,
    },
  },
  '/adddoc': {
    cardType: 5,
    background: '#FFFFFFBF',
    title: 'Insert Additional Documents',
    header: {
      title: `Insert Additional Documents`,
      description:
        'Only once these additional documents are submitted to the EMA, we will receive the final approval for the published output.',
    },
    // breadcrumbs: [{ title: '', target: '' }],
    button: [
      {
        title: 'QC Published Output',
        target: ''
      },
      {
        title: 'MU Distribution',
        target: 'mudist',
      },
    ]
  },
  '/compur': {
    cardType: 2,
    background: '#FFFFFFBF',
    title: 'Communicate Publishing Request',
    header: {
      title: `Communicate Publishing Request`,
      description:
        'The EMA route demands a PO, a Cover Letter, separate regional appendices, and creation of the content plan and submission in VV. Now, the publishing request is placed & finalised via POF.',
    },
    // breadcrumbs: [{ title: '', target: '' }],
    list: [
      {
        title: 'QC Published Output',
        target: 'qcpuo',
      },
      {
        title: 'Submission request sent to Publishing',
        target: 'subrpu',
      },
    ],
    enabledItem: {
      index: 'all'
    }
  },
  '/recpuo': {
    cardType: 5,
    background: '#FFFFFFBF',
    title: 'Recieve Published Output',
    header: {
      title: `Recieve Published Output`,
      description:
        'Compared to the Global Framework, the E.M.A follows a more detailed process. The final published-output will be submitted via the E.M.A Gateway by the publishing team. ',
    },
    // breadcrumbs: [{ title: '', target: '' }],
    button: [
      {
        title: 'Submission request sent to Publishing',
      },
      {
        title: `QC Published Output`,
        target: 'qcpuo',
      },
    ],
    enabledItem: {
      index: 'all',
    },
    voiceOver: {
      index: 4,
    },
  },
  '/subrpu': {
    cardType: 5,
    background: '#FFFFFFBF',
    title: 'Submission request sent to Publishing',
    header: {
      title: `Submission request sent to Publishing`,
      description: 'As per the EU framework, PSUR distribution is initiated via the EMA Gateway.',
    },
    // breadcrumbs: [{ title: '', target: '' }],
    button: [
      {
        title: 'Communicate Publishing Request',
        target: 'compur',
      },
      {
        title: 'Recieve Publishing Output',
        target: 'recpuo',
      },
    ],
    enabledItem: {
      index: 'all',
    },
  },
  '/qcpuo': {
    cardType: 2,
    background: '#FFFFFFBF',
    title: 'QC Published Output',
    header: {
      title: `QC Published Output`,
      description:
        'This has to undergo a review by E.M.A.  They may either approve the published-output, or request some additional documents in order to do so.',
    },
    // breadcrumbs: [{ title: '', target: '' }],
    list: [
      {
        title: 'MU Distribution',
        target: 'mudist',
      },
      {
        title: 'Insert Additional Documents',
        target: 'adddoc',
      },
    ],
    enabledItem: {
      index: 'all',
    },
    voiceOver: {
      index: 4,
    },
  },

  '/final': {
    cardType: 6,
    background: '#FFFFFFBF',
    title: 'Final',
    video: '/video/Novo_Impactvideo_v2.mp4',
    header: {
      title: `PSUR Impact`,
      description:
        'The objective of the PSUR is to present a comprehensive and critical analysis of the risk-benefit balance of the product, taking into account new or emerging safety information in the context of cumulative information on risk and benefits. ',
    },
    // breadcrumbs: [{ title: '', target: '' }],
    button: [
      {
        title: `Process Complete!`,
        target: '/nndc',
      },
    ],
    voiceOver: {
      index: -1,
    },
  },

  '/idea': {
    cardType: 5,
    background: '#FFFFFFBF',
    title: 'Idea',
    header: {
      title: `Idea`,
      description:
        'The life-cycle of a product starts from the idea where the focus is on how to improve the quality of life for patients.',
    },
    // breadcrumbs: [{ title: '', target: '' }],
    list: [],
    button: [
      {
        title: 'Value Chain',
        target: '/valuechain'
      },
      {
        title: 'Early R&D',
        target: '/valuechain/earlyrd'
      },
    ],
    enabledItem: {
      index: 'all',
    },
    voiceOver: {
      index: -1,
    },
  },
  '/earlyrd': {
    cardType: 5,
    background: '#FFFFFFBF',
    title: 'Early R & D',
    header: {
      title: `Early R & D`,
      description:
        'In early R&D it is decided if the idea should be defined as a project. Often the researchers work with several molecules in order to find the best. The molecules are made into a medical product during the manufacturing process to be used in pre-clinical studies carried out in animals to test the toxicity and later in human clinical trials where the safety and efficacy of the product is determined.',
    },
    // breadcrumbs: [{ title: '', target: '' }],
    button: [
      {
        title: 'Idea',
        target: '/valuechain/idea'
      },
      {
        title: 'Product Registration',
        target: '/valuechain/productreg'
      }
    ],
  },
  '/productreg': {
    cardType: 5,
    background: '#FFFFFFBF',
    title: 'Product Registration',
    header: {
      title: `Product Registration`,
      description:
        'When the safety and efficacy is determined the data will be submitted to the HA in order to register the product on the market and get a license to operate.',
    },
    // breadcrumbs: [{ title: '', target: '' }],
    button: [{
      title: 'Early R&D',
      target: '/valuechain/earlyrd'
    }
      , {
      title: 'Product Launch',
      target: '/valuechain/productlaunch'
    }]
  },
  '/productlaunch': {
    cardType: 5,
    background: '#FFFFFFBF',
    title: 'Product Launch',
    header: {
      title: `Product Launch`,
      description:
        'After approval the product will be launched in to the market and made available to the patients. ',
    },
    // breadcrumbs: [{ title: '', target: '' }],
    button: [
      { title: 'Product Registration', target: '/valuechain/productreg' },
      { title: 'Post Marketing LCM', target: '/valuechain/postmarklcm' }
    ],
    list: [],
    enabledItem: {
      index: 'all',
    },
    voiceOver: {
      index: -1,
    },
  },
  '/postmarklcm': {
    cardType: 5,
    background: '#FFFFFFBF',
    title: 'Post Marketing LCM',
    header: {
      title: `Post Marketing LCM`,
      description: 'In the post-marketing LCM stage the license to operate is maintained.',
    },
    // breadcrumbs: [{ title: '', target: '' }],
    button: [
      { title: 'Product Launch', target: '/valuechain/productlaunch' },
      { title: 'De-Registered', target: '/valuechain/deregistered' }
    ],
  },
  '/deregistered': {
    cardType: 5,
    background: '#FFFFFFBF',
    title: 'De-Registered',
    header: {
      title: `De-Registered`,
      description:
        'Deregistration is the last step in the life-cycle of a product and it is often a business decision to deregister the product from the market.',
    },
    button: [
      { title: 'Post Marketing LCM', target: '/valuechain/postmarklcm' },
      { title: '', target: '' }
    ]
  },
}
