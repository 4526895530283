import { MapControls, OrbitControls, Sphere } from '@react-three/drei'
import { useFrame, useThree } from '@react-three/fiber'
import { button, useControls } from 'leva'
import React, { useEffect, useRef, useState } from 'react'
import CameraComponent from '../../../../example/tween-camera/CameraComponent'
import { useCustomCamera, useDesigner } from '../../stores'
import { CustomControls } from './CustomControls'
import { TweenManager } from './TweenManager'
import { designerEnabled } from '../../settings'
// import { useThree } from 'react-three-fiber'
import * as THREE from 'three'
import { object } from 'prop-types'
export const Camera = React.memo(({ name }) => {
  // const { camera } = useThree()
  const controls = useRef()
  const { camera } = useThree()
  const ref = useRef()
  const selected = useRef()
  const smallRef = useRef()
  const { selectedHp } = useDesigner()

  const { setHotspot } = useControls({
    setHotspot: button(() => useCamera.setHotspotPosition({ ...controls.current.target })),
  })
  // const { camera } = useThree()
  const { fov } = useControls({
    fov: { value: 75 },
  })
  const { objectBounds, ...useCamera } = useCustomCamera()
  const [suspendTween, set] = useState(false)
  useEffect(() => {
    useCamera.setCamera(camera)
    useCamera.setControls(controls.current)
    set(false)
    camera.fov = 30
  }, [fov])

  useEffect(() => {
    if (objectBounds) {
    }
  }, [objectBounds])
  useEffect(() => {
    // if (selected.current != null && selected.current)
    if (selectedHp && selectedHp.position && selected.current && designerEnabled)
      selected.current?.position.set(...Object.values(JSON.parse(selectedHp.position)))
  }, [selected, selectedHp])
  useEffect(() => {
    // controls.current.screenSpacePanning = designerEnabled
    controls.current.listenToKeyEvents(window)
  }, [])
  useFrame(() => {
    // camera.updateMatrix()
    camera.updateProjectionMatrix()
    // camera.up.set(0, 1, 0)
    if (designerEnabled) {
      ref.current.position.set(...Object.values(controls.current.target))
      smallRef.current.position.set(...Object.values(controls.current.target))
    }
  })
  function visibleBox(z) {
    var t = Math.tan(THREE.Math.degToRad(camera.fov) / 2)
    var h = t * 2 * z
    var w = h * camera.aspect
    return new THREE.Box2(new THREE.Vector2(-w, h), new THREE.Vector2(w, -h))
  }
  var minPan = useRef()
  var maxPan = useRef()
  // useEffect(()=>{

  // },[camera])
  useEffect(() => {
    if (objectBounds) {
      let left = (camera.position.y * window.innerHeight) / window.innerWidth
      // console.log('lleft', left)
      // minPan.current = { ...objectBounds.min }
      // maxPan.current = { ...objectBounds.max }

      // minPan.current.x += left
      // minPan.current.z += 8
      // maxPan.current.x -= left
      // maxPan.current.z -= 8
    }
  }, [objectBounds, camera])
  const onControlsChange = (e) => {
    // console.log(camera)
    if (objectBounds?.min) {
      if (name === 'nndc' && !useCamera.isTweening) {
        // pan bounds
        let camerabox = visibleBox(camera.position.y)
        let right = 10 + 26 + 50
        let left = 32 + 26 + 50
        let top = 29 + 26 + 50
        let bottom = 29 + 26 + 50
        let minPan = objectBounds.min.clone()
        let maxPan = objectBounds.max.clone()
        minPan.z += left
        maxPan.z -= right
        minPan.x += top
        maxPan.x -= bottom
        var _v = new THREE.Vector3()
        _v.copy(controls.current.target)
        controls.current.target.clamp(minPan, maxPan)
        _v.sub(controls.current.target)
        camera.position.sub(_v)
      }
    }
  }
  return (
    <>
      <OrbitControls
        screenSpacePanning={designerEnabled}
        // screenSpacePanning={false}
        // rotateSpeed={useCamera.isTweening?0:1}
        enableZoom={designerEnabled}
        // enablePan={designerEnabled || name === 'nndc'}
        enableRotate={designerEnabled}
        // panSpeed={name === 'nndc' || designerEnabled ? 2 : 0}
        panSpeed={designerEnabled ? 1 : name === 'nndc' ? 2 : 0.001}
        enableDamping={!designerEnabled}
        mouseButtons={{
          LEFT: THREE.MOUSE.RIGHT,
          RIGHT: THREE.MOUSE.LEFT,
        }}
        onChange={onControlsChange}
        zoomSpeed={1.8}
        ref={controls}
      />
      {/* <Sphere transp ref={ref} /> */}
      {designerEnabled && (
        <>
          <mesh ref={ref}>
            <sphereBufferGeometry args={[1, 50, 50]} />
            <meshBasicMaterial transparent opacity={0.5} />
          </mesh>
          <mesh ref={smallRef}>
            <sphereBufferGeometry args={[0.1, 50, 50]} />
            <meshBasicMaterial color="blue" />
          </mesh>
          <mesh position={[0, -400, 0]} ref={selected}>
            <sphereBufferGeometry args={[1, 50, 50]} />
            <meshBasicMaterial color="green" transparent opacity={0.5} />
          </mesh>
          {/* <CustomControls controls={controls} /> */}
          {/* <CameraComponent /> */}
        </>
      )}
      {!suspendTween && camera && controls.current && <TweenManager />}
    </>
  )
})
