import React, { useEffect } from 'react'
import { useLocation } from 'wouter'
import { rooms, useAppState, useLoadingManager } from '../../stores'
// import
const parseLoc = (loc) => {
  // console.log(_loc)
  // let newLoc = new URL(window.location.origin + _loc)
  // let loc = newLoc.toString()
  // console.log(loc)
  let newLoc = loc
    .split('//')
    .join('/')
    .split('/')
    .filter((loc) => loc !== '')
  // loc.length === 0 && loc.push('/')
  console.log(newLoc)
  return newLoc
}
const findRoom = (locs, rooms) => {
  locs.reverse()
  locs = locs.filter((loc) => loc !== '')
  let room = null
  locs.find((loc) => {
    room = rooms.find((room) => room.name === loc)

    if (room) {
      return true
    }
  })
  return room ? room : rooms.find((room) => room.name === 'base')
}
const findHotspot = (locs, currRoom) => {}
export const RoutesManager = () => {
  const [location, setLocation] = useLocation()
  const { allRooms, room, hotspotIdx, setAll, currentContent } = useAppState()
  const loader = useLoadingManager()

  useEffect(() => {
    if (allRooms.length) {
      let parsedLoc = parseLoc(location)
      let currIsRoom = parsedLoc.length
        ? allRooms.find((currRoom) => currRoom.name === parsedLoc[parsedLoc - 1])
        : null
      let currRoom = findRoom([...parsedLoc], allRooms)
      let currHp = currRoom.hotspots.find((hp) => hp.name === parsedLoc[parsedLoc.length - 1])
      let isLoaded = loader.itemsDone.includes(currRoom.name)
      // if (!room) {
      //   setAll({ currentContent: currRoom, room: currRoom })
      // }
      currRoom !== room && loader.setFadeIn(true)
      setTimeout(() => {
        if (currIsRoom || !room) {
          if (currHp) setAll({ currentContent: currHp, room: currRoom })
          else setAll({ currentContent: currRoom, room: currRoom })
        } else {
          setAll({ currentContent: currHp || currRoom, room: currRoom })
        }
      }, [currRoom !== room && room ? 500 : 0])
      // if (currHp) {
      //   setAll({ currentContent: currHp })
      // }
    }
  }, [location, allRooms])
  useEffect(() => {}, [currentContent])
  return null
  // <div>
  //   <button onClick={() => setLocation('/something/sx')}>push history</button>
  //   <button onClick={() => setLocation('/sx')}>push history</button>
  //   <p>{location}</p>
  // </div>
}
