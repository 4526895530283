import { css, StyleSheet } from 'aphrodite'
import axios from 'axios'
import { useState } from 'react'
import { getFormData } from '../designerHelpers'
import { getFileUrl } from '../settings'
import { useEffect } from 'react'
import { useAppState, useCustomCamera } from '../stores'
import PropTypes from 'prop-types'
import { hotspotFormElements, roomFormElements } from '../settings'
import { ListForm } from './ListForm'
// let toDisable = ['roomName', 'id', 'name']

export const DynamicForm = ({
  defaultParams,
  destinationUrl,
  hotspot,
  roomName,
  hotspotOptions,
}) => {
  const { fetchRooms } = useAppState()
  const [params, setParams] = useState(JSON.parse(JSON.stringify(defaultParams)))
  const { camera, controls } = useCustomCamera()
  const { hotspotPosition } = useCustomCamera()
  const [shouldPosition, setShouldPosition] = useState(false)
  const onFileChange = (e, key) => {
    setParams((state) => {
      state[key] = e.target.files[0]
      console.log('on file change', state)
      return { ...state }
    })
  }
  useEffect(() => {
    console.log('params changed', params)
  }, [params])
  const refreshRooms = () => {
    setTimeout(() => fetchRooms(), 500)
  }
  useEffect(() => {
    // delete defaultParams.view
    // let { view, ...rest } = defaultParams
    console.log('default params changed apparently')
    const temp = hotspot
      ? { ...hotspotFormElements, ...defaultParams }
      : { ...roomFormElements, ...defaultParams }
    delete temp.view
    delete temp.position
    delete temp.hotspots
    temp.suboptions = JSON.parse(temp.suboptions)
    setParams({ ...temp })
  }, [defaultParams])
  const [shouldView, setShouldView] = useState(false)

  const onSubmit = async (e) => {
    e.preventDefault()
    console.log('inside on submit', params)
    let toSend = { ...params }
    Object.keys(toSend).forEach((key) => {
      if (typeof toSend[key] == 'object') {
        console.log(key, toSend[key])
        if (!toSend[key].lastModified && key !== 'suboptions') delete toSend[key] // delete file attribute if nothing uploaded
      }
    })
    if (hotspot) toSend.roomName = roomName
    if (shouldView) {
      toSend.view = JSON.stringify({
        position: { ...camera.position },
        lookAt: { ...controls.target },
        fov: camera.fov,
      })
    }
    if (hotspot && shouldPosition) {
      toSend.position = JSON.stringify(hotspotPosition)
    }
    if (hotspot) {
    }
    toSend.suboptions = JSON.stringify(toSend.suboptions)
    let formData = getFormData(toSend)
    try {
      let response = await axios.post(destinationUrl, formData)
      console.log('submit res: ', response)
    } catch (err) {
      console.error('submit phail', err)
    }
    // refreshRooms()
  }
  const onChange = (e, key) => {
    setParams((state) => {
      state[key] = e
      return { ...state }
    })
  }
  const deleteItem = () => {
    try {
      axios.delete(destinationUrl + (params.name ? params.name : params.id)) // fix later, need better to check if hotspot or room
    } catch (err) {
      console.error(err)
    }
    setParams({})
    // refreshRooms()
  }
  const onListChange = (e) => {
    setParams((state) => {
      let temp = {
        ...state,
        suboptions: typeof state.suboptions === 'object' ? state.suboptions : [],
      }
      temp.suboptions = [...temp.suboptions, e.target.value]
      return temp
    })
  }
  const onResetList = (e) => {
    setParams((state) => {
      return { ...state, suboptions: [] }
    })
  }
  return (
    <>
      {/* <p>room form</p> */}
      {params && (
        <form className={css(styles.container)} onSubmit={onSubmit}>
          <hr />
          {params &&
            Object.keys(params).map((key) => {
              return (
                key !== 'id' &&
                key !== 'roomName' && (
                  <div className={css(styles.group)}>
                    <label className={css(styles.label)}>
                      {key}
                      {key == 'suboptions'
                        ? '(comma seperated, not case sensitive)'
                        : key === 'name'
                        ? '(no spaces, this is pushed to the url)'
                        : ''}
                    </label>
                    {key === 'suboptions' ? (
                      <ListForm
                        set={setParams}
                        selected={typeof params.suboptions === 'object' ? params.suboptions : []} // <ListForm // <textarea value={params[key]} onChange={(e) => onChange(e.target.value, key)} />
                        possibleValues={hotspotOptions}
                        onChange={onListChange}
                        onReset={onResetList}
                      />
                    ) : key === 'desc' ? (
                      <textarea
                        value={params[key]}
                        onChange={(e) => onChange(e.target.value, key)}
                      />
                    ) : typeof params[key] === 'string' ? (
                      <input value={params[key]} onChange={(e) => onChange(e.target.value, key)} />
                    ) : (
                      <>
                        <input type="file" onChange={(e) => onFileChange(e, key)} />
                        {params[key]?.type === 'image' && (
                          <img
                            className={css(styles.thumb)}
                            height="60px"
                            width="60px"
                            src={getFileUrl(params[key]?.url)}
                          />
                        )}
                      </>
                    )}
                  </div>
                )
              )
            })}
          <label className={css(styles.label)}>should include view</label>
          <input
            className={css(styles.label)}
            type="checkbox"
            value={shouldView}
            onChange={() => setShouldView((view) => !view)}
          />
          {hotspot && (
            <>
              <label className={css(styles.label)}>should include hotspot position</label>
              <input
                className={css(styles.label)}
                type="checkbox"
                value={shouldPosition}
                onChange={() => setShouldPosition((view) => !view)}
              />
            </>
          )}
          <input style={{ marginTop: '2vh' }} disabled={!params.name} type="submit" />

          {(params.name || params.id) && (
            <button type="button" onClick={deleteItem} style={{ marginTop: '10vh' }}>
              delete
            </button>
          )}
        </form>
      )}
    </>
  )
}
DynamicForm.propTypes = {
  defaultParams: PropTypes.Object,
  destinationUrl: PropTypes.String,
  hotspot: PropTypes.Object,
  roomName: PropTypes.String,
}
const styles = StyleSheet.create({
  container: {
    width: '100%',
    opacity: 1,
    pointerEvents: 'auto',
    fontFamily: 'Arial',
  },
  group: {
    display: 'block',
    padding: '0.21vw',
  },
  label: {
    fontSize: '0.9em',
    display: 'block',
    margin: 0,
    marginTop: '1vh',
  },
  thumb: {
    float: 'right',
    marginTop: '1%',
  },
})
