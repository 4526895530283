import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'wouter'
import Cards from '../context/hud/Cards'
import { hud } from '../constants'
// import Branding from '../framework/panorama-viewer/context/ui/Branding'
// import Branding from '/context/ui/Branding'
// import MapButton from '../framework/panorama-viewer/context/ui/MapButton'
// import PanoramaInfo from '../framework/panorama-viewer/context/ui/PanoramaInfo'
// import Spacer from '../framework/panorama-viewer/context/ui/Spacer'
import HudManager from '../framework/panorama-viewer/core/HudManager'
// import TextPopupExample from '../framework/panorama-viewer/context/ui/TextPopupExample'
// import FullScreenPopupExample from './context/ui/FullscreenPopupExample'
// import { displayComponents } from '../framework/panorama-viewer/hotspot-types'
import { useHud } from '../framework/panorama-viewer/stores'
import VoiceOver from './VoiceOver'
import { VideoPlayer } from './VideoPlayer'
import { voiceOverSource } from '../voiceOversSource'
import { Muted, UnMuted } from '../svgs/MuteUnmuteSvg'
import { ClickToDrag } from '../context/assets/icons/ClickToDrag'
import { Renders } from './Renders'
import { ValueButton } from '../svgs/ValueButton'
// import VoiceOver from './VoiceOver'
// import StaticUI from './context/ui/StaticUI'
const getEndLocation = (location) =>
  location === '' ? '/' : '/' + location.split('/')[location.split('/').length - 1]
export default function HudSystem() {
  const [location] = useLocation()
  const [mute, setMute] = useState(false)
  // const { routes } = useStatic()
  // const [content, setContent] = useState(null)
  // const [renderThis, setRenderThis] = useState(null)
  const { setRequestedFunction } = useHud()
  // const [isMap, setIsMap] = useState(false)
  // function showMap() {
  //   setIsMap(!isMap)
  // }

  // function closeConditionals() {
  //   setRequestedFunction(null)
  // }
  const endLocation = useMemo(
    () => (location === '' ? '/' : '/' + location.split('/')[location.split('/').length - 1]),
    [location]
  )
  useEffect(() => {
    setRequestedFunction(null)
    // console.log(endLocation)
    // // console.log(
    //   'location changed ',
    //   location === '' ? '/' : '/' + location.split('/')[location.split('/').length - 1]
    // )
  }, [location])

  // useEffect(() => {
  //   const unsub = useHud.subscribe(
  //     (func) => {
  //       // setRenderThis(func)
  //     },
  //     (state) => state.requestedFunction
  //   )
  //   return function cleanup() {
  //     unsub()
  //   }
  // }, [])

  return hud[endLocation] && endLocation !== '/' ? (
    <>
      {/* The Three Division Grid System */}
      {/* {content != null && ( */}
      <>
        {endLocation === '/nndc' && <ClickToDrag />}
        {hud[endLocation] !== -1 && (
          <HudManager
            backgroundcolor={hud[endLocation]?.background}
            left={<Cards cardObject={hud[endLocation]} defaultCardObject={hud.default} />}
          />
        )}
      </>
      {voiceOverSource[endLocation] && (
        <VoiceOver
          // pageObject={hud[endLocation]}
          url={voiceOverSource[endLocation]}
          muted={mute}
          // playState={hud.location7.voiceOver !== null ? true : false}
        />
      )}
      <div
        style={{
          position: 'absolute',
          cursor: 'pointer',
          width: '2.9vw',
          right: '2.5vh',
          top: '2.5vh',
          zIndex: '999',
        }}
      >
        {mute ? (
          <Muted onClick={() => setMute((state) => !state)} />
        ) : (
          <UnMuted onClick={() => setMute((state) => !state)} />
        )}
      </div>
      {/* )} */}
      {hud[endLocation].video && <VideoPlayer video={hud[endLocation].video} />}
      <Renders render={hud[endLocation].render} />
      {/* <ValueButton /> */}
    </>
  ) : null
}
