export const voiceOverSource = {
  '/': '/audio/nn/intro.mp3',
  '/nndc': '/audio/nn/nndc.mp3',
  '/slcm': '/audio/nn/slcm.mp3',
  '/radb': '/audio/nn/radb.mp3',
  '/final': '/audio/nn/final.mp3',
  '/gra': '/audio/nn/gra.mp3',
  '/dismai': '/audio/nn/dismai.mp3',
  '/subrpu': '/audio/nn/subrpu.mp3',
  '/psur': '/audio/nn/psur2.mp3',
  '/psuroverview': '/audio/nn/psuroverview.mp3',
  '/psurtimeline': '/audio/nn/psurtimeline.mp3',
  '/adddoc': '/audio/nn/adddoc.mp3',
  '/compur': '/audio/nn/compur.mp3',
  '/createrasp': '/audio/nn/createrasp.wav',
  '/mudist': '/audio/nn/mudist.mp3',
  '/qcpuo': '/audio/nn/qcpuo.mp3',
  '/recpuo': '/audio/nn/recpuo.mp3',
  '/review': '/audio/nn/review.wav',
  '/subrpu': '/audio/nn/subrpu.mp3',
  '/eu_glo': '/audio/nn/euglo.mp3',
  '/valuechain': '/audio/nn/valuechain.mp3',
  '/idea': '/audio/nn/idea.mp3',
  '/earlyrd': '/audio/nn/earlyrd.mp3',
  '/productreg': '/audio/nn/productregistration.mp3',
  '/productlaunch': '/audio/nn/productlaunch.mp3',
  '/postmarklcm': '/audio/nn/postmarketinglcm.mp3',
  '/deregistered': '/audio/nn/deregistration.mp3',
}
