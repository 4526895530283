/**
 *  Summary :
 *  targetPos - it can be set to the custom tween position or tween position from the store.(by default set to 0)
 *  tagetControl - it can be set to the custom tween rotation or tween rotation from the store.(by default set to 0)
 *  .onStart() - it is a chain function. Multiple animation can be added inside these function
 *  .onUpdate() - it updates target controls on each frame
 */

import { useEffect } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min'
import * as THREE from 'three'

function Tween(props) {
  const { camera } = useThree()
  let targetPos = new THREE.Vector3(0, 0, 0)
  let targetControl = new THREE.Vector3(0, 0, 0)

  useEffect(() => {
    targetPos = new THREE.Vector3(
      props.tweenPoint.position[0],
      props.tweenPoint.position[1],
      props.tweenPoint.position[2]
    )
    targetControl = new THREE.Vector3(
      props.tweenPoint.control[0],
      props.tweenPoint.control[1],
      props.tweenPoint.control[2]
    )

    let t1 = false
    let t2 = false

    let tween = new TWEEN.Tween(camera.position)
      .to(targetPos, props.tweenDuration)
      .easing(props.tweenInterpolation)
      .onStart(function () {
        new TWEEN.Tween(props.controls.current.target)
          .to(targetControl, props.tweenDuration - 100)
          .easing(props.tweenInterpolation)
          .onUpdate(function () {
            camera.lookAt(targetControl)
          })
          .onComplete(function () {
            t1 = true
            if (t2) {
              props.tweenFinished()
            }
          })
          .start()
      })
      .onComplete(function () {
        t2 = true
        if (t1) {
          props.tweenFinished()
        }
      })
      .start()
    return () => {
      tween.stop()
    }
  }, [
    props.tweenPoint,
    props.controls,
    props.customTweenPoint,
    props.customControlLookat,
    props.isCustomTween,
    props.tweenInterpolation,
    props.tweenDelay,
  ])

  useFrame(() => {
    TWEEN.update()
  })
  return null
}

export default Tween
