import { css, StyleSheet } from 'aphrodite'
import { button, useControls } from 'leva'
import { useEffect, useMemo, useState } from 'react'
import { hotspotFormElements, roomFormElements } from '../settings'
import { useAppState, useCustomHistory, useDesigner } from '../stores'
import { RoomForm } from './RoomForm'
import { DynamicForm } from './DynamicForm'
import { baseUrl } from '../settings'
import PropTypes from 'prop-types'

const getRoomNames = (rooms) => {
  return rooms.map((x) => x.name)
}
const getHotspotNames = (hotspot) => {
  return hotspot?.map((hp) => hp.name)
}
const getRoombyName = (name, rooms) => {
  let room = { ...rooms.find((room) => room.name === name) }
  // if(shouldRemoveHps)delete room.hotspots
  return room
}
export const Designer = () => {
  const useApp = useAppState()
  const { setSelectedHp } = useDesigner()
  const { room_hotspot_toggle, hide } = useControls({
    room_hotspot_toggle: { value: true },
    hide: { value: true },
    refresh: button(() => useApp.fetchRooms()),
  })
  useEffect(() => {
    console.log('room changed', useApp.room)
  }, [useApp.room])
  const [currRoom, setCurrRoom] = useState('')
  const [currHotspot, setCurrHotspot] = useState('')
  const customHistory = useCustomHistory()
  const getHotspotbyName = (name) => {
    return getRoombyName(currRoom, useApp.allRooms, false)?.hotspots?.find((hp) => hp.name === name)
  }
  // const selectedHotspot = useMemo(()=>{

  // },[currHotspot, useApp])
  const selectedRoom = useMemo(() => {
    return { ...useApp.allRooms.find((room) => room.name === currRoom) }
  }, [currRoom, useApp])
  useEffect(() => {
    console.log('curr room changed')
  }, [currRoom])
  useEffect(() => {
    if (currRoom) customHistory.setParams({ location: currRoom })
  }, [currRoom])
  useEffect(() => {
    setSelectedHp(getHotspotbyName(currHotspot))
  }, [currHotspot])
  return (
    useApp.allRooms &&
    !hide && (
      <div className={css(styles.container)}>
        <div className={css(styles.roomHud)}>
          <h2>Designer</h2>
          {room_hotspot_toggle ? (
            <>
              <select value={currRoom} onChange={(e) => setCurrRoom(e.target.value)}>
                <option value="">new room</option>
                {getRoomNames(useApp.allRooms).map((name, i) => (
                  <option key={i} value={name}>
                    {name}
                  </option>
                ))}
              </select>
              <DynamicForm
                defaultParams={
                  currRoom ? selectedRoom : roomFormElements
                  // currRoom ? selectedRoom : roomFormElements
                }
                destinationUrl={baseUrl + 'rooms/'}
                hotspotOptions={
                  currRoom
                    ? getRoombyName(currRoom, useApp.allRooms).hotspots.map((x) => x.name)
                    : []
                }
              />
            </>
          ) : (
            <>
              <h2>hotspots</h2>
              <select
                style={{ width: '11vw' }}
                value={currHotspot}
                onChange={(e) => setCurrHotspot(e.target.value)}
              >
                <option value="">new hotspot</option>
                {getHotspotNames(getRoombyName(currRoom, useApp.allRooms, false).hotspots)?.map(
                  (id, i) => {
                    return (
                      <option key={i} value={id}>
                        {id}
                      </option>
                    )
                  }
                )}
              </select>
              <DynamicForm
                roomName={currRoom}
                hotspot
                destinationUrl={baseUrl + 'hotspots/'}
                defaultParams={
                  getHotspotbyName(currHotspot)
                    ? getHotspotbyName(currHotspot)
                    : hotspotFormElements
                }
                hotspotOptions={
                  currRoom
                    ? getRoombyName(currRoom, useApp.allRooms).hotspots.map((x) => x.name)
                    : []
                }
              />
            </>
          )}
          {/* < */}
        </div>
      </div>
    )
  )
}
Designer.propTypes = {
  rooms: PropTypes.Object,
}
const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 1,
    fontFamily: 'Arial',
    pointerEvents: 'none',
  },
  roomHud: {
    position: 'absolute',
    padding: '0.2%',
    right: 0,
    top: 0,
    width: '30%',
    opacity: 1,
    overflow: 'auto',
    backgroundColor: 'crimson',
    pointerEvents: 'auto',
  },
})
