import { BakeShadows, Html, useDetectGPU } from '@react-three/drei'
import { useThree } from '@react-three/fiber'
import React, { useState, useEffect, useRef, Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useLocation } from 'wouter'
import CustomSAO from '../../../context/CustomSAO'
import { Camera } from '../core/3d/Camera'
import { Lights } from '../core/3d/Lights'
import ParallaxEffect from '../core/3d/ParallaxEffect'
import { Skybox } from '../core/3d/Skybox'
import { getFileUrl } from '../settings'
import { useAppState } from '../stores'
import { HotspotManager } from './hotspots/HotspotManager'
import { ModelAnimations } from './ModelAnimations'
import { Room } from './Room'
import { isMobile } from 'react-device-detect'
import { designerEnabled } from '../settings'

export const MainScene = React.memo(() => {
  let timeout
  const [suspendRoom, setSuspend] = useState(false)
  const GPUTier = useDetectGPU()
  const { allRooms, room, currentContent, videoPlaying } = useAppState()
  const [location, setLocation] = useLocation()
  const [urlInt, setUrlInt] = useState()
  // const [currLocation, setCurrLocation] = useState(allRooms.find((room) => room.name === location))
  const [currLocation, setCurrLocation] = useState()
  const { gl } = useThree()
  // const actions = useRef()
  const [actions, setActions] = useState()
  const getRoomData = (idx) => {
    return allRooms[idx]
  }
  useEffect(() => {
    // gl.shadowMap.autoUpdate = true
  }, [])
  useEffect(() => {
    // setSuspend(true)
    // timeout = setTimeout(() => {
    //   setSuspend(false)
    // }, [1000])
    // return () => clearTimeout(timeout)
    setUrlInt(null)
    const newUrl =
      GPUTier.tier != null &&
      (isMobile || (GPUTier.tier <= 1 && GPUTier.tier != null)
        ? room.model.lr
        : GPUTier.tier === null || GPUTier.tier >= 2
        ? room.model.hr
        : room.model.lr)
    setTimeout(() => setUrlInt(designerEnabled ? room?.model.url : newUrl), 500)
  }, [room])
  return (
    <>
      {/* <ErrorBoundary FallbackComponent={() => <Html>bad glb</Html>}> */}
      <Suspense fallback={null}>
        {/* <Box /> */}
        {/* {!suspendRoom && location && location != '-1' && <Room />} */}
        <Camera name={currentContent?.name} />
        {urlInt && (
          <Room
            // ref={actions}
            setActions={setActions}
            videoPlaying={videoPlaying}
            name={room.name}
            shouldRender={!suspendRoom}
            url={getFileUrl(urlInt)}
          />
        )}
        {/* {room.name && <Lights roomName={room.name} />} */}
        {room?.hotspots && <HotspotManager hotspots={room.hotspots} />}
        {/* <ParallaxEffect /> */}
        <Skybox />
        {/* {room?.name === 'psur' && <CustomSAO />} */}
        {/* <BakeShadows /> */}
        {currentContent?.name && actions && (
          <ModelAnimations actions={actions} name={currentContent.name} />
        )}
      </Suspense>
      {/* </ErrorBoundary> */}
    </>
  )
})
