import { css, StyleSheet } from 'aphrodite'
import React from 'react'
import logo from '../assets/logo/NovoNordiskBlueLogo.webp'

export default function Branding() {
  return (
    <div>
      <img style={{ position: 'absolute', left: '1vw', top: '2vh', height: '8vh' }} src={logo} />
    </div>
  )
}

const styles = StyleSheet.create({
  container: {},
  logo: {
    width: '5vw',
  },
})
