import { css, StyleSheet } from 'aphrodite'
import React from 'react'

export function BlueBoardText() {
  return (
    <div className={css(styles.container)}>
      <div>
        <div style={{ paddingTop: 25 }}>
          <h3 className={css(styles.heading)}>Rare Diabetes & Obesity</h3>
          <p className={css(styles.titleBlue)}>PSUR</p>
          <p className={css(styles.titleBlue)}>NDA</p>
          <p className={css(styles.titleBlue)}>Post Approval</p>
          <p className={css(styles.titleBlue)}>Q&A From Health Authority</p>
          <p className={css(styles.titleBlue)}>RMP</p>
          <p className={css(styles.titleBlue)}>PSUR</p>
          <p className={css(styles.titleBlue)}>Investigator Brochure</p>
          <p className={css(styles.titleBlue)}>DSUR</p>
          <p className={css(styles.titleBlue)}>PIP</p>
          <p className={css(styles.titleBlue)}>Annual Report</p>
          <p className={css(styles.titleBlue)}>Commitments</p>
          <p className={css(styles.titleBlue)}>Clinical Trials Application</p>
        </div>
      </div>
    </div>
  )
}

export default function Text() {
  return (
    <div className={css(styles.container)}>
      <span className={css(styles.text1, styles.title)}>IDEA</span>
    </div>
  )
}
export function Text2() {
  return (
    <div className={css(styles.container)}>
      <span className={css(styles.text2, styles.title)}>EARLY R&D</span>
    </div>
  )
}
export function Text3() {
  return (
    <div className={css(styles.container)}>
      <span className={css(styles.text3, styles.title)}>
        <p style={{ margin: '0' }}>PRODUCT</p>
        <p style={{ margin: '0' }}>REGISTRATION</p>
      </span>
    </div>
  )
}
export function ValuechainHeading() {
  return <h1 className={css(styles.valuechain)}>VALUE CHAIN</h1>
}
export function Text4() {
  return (
    <div className={css(styles.container)}>
      <span className={css(styles.text4, styles.title)}>
        <p style={{ margin: '0' }}>PRODUCT</p>
        <p style={{ margin: '0' }}>LAUNCH</p>
      </span>
    </div>
  )
}
export function Text5() {
  return (
    <div className={css(styles.container)}>
      <span className={css(styles.text5, styles.title)}>
        <p style={{ margin: '0' }}>POST MARKETING </p>
        <p style={{ margin: '0' }}>LCM</p>
      </span>
    </div>
  )
}
export function Text6() {
  return (
    <div className={css(styles.container)}>
      <span className={css(styles.text6, styles.title)}>DE-REGISTERED</span>
    </div>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'inline-flex',
    position: 'relative',
  },
  title: {
    fontSize: 10,
    color: 'white',
    fontFamily: 'roboto',
  },
  valuechain: {
    fontSize: 100,
    color: 'white',
  },
  // text1: {
  //   // padding: '0 0 0 8.62vh',
  //   left:'3vh',
  //   position:'absolute'
  // },
  // text2: {
  //   // padding: '0 4.64vh 0 12.6vh'
  //   position:'absolute',
  //   left:'0vh'
  // },
  // text3: {
  //   padding: '0 4.64vh'
  // },
  // text4: {
  //   padding: '0 7.29vh 0 2vh'
  // },
  // text5: {
  //   padding: '0 3.97vh 0 2vh'
  // },
  // text6: {
  //   padding: '0 13.26vh 0 0'
  // },
  titleBlue: {
    fontSize: 10,
    color: 'white',
    fontFamily: 'roboto',
    margin: '12px',
  },
  heading: {
    fontSize: 11,
    color: 'white',
    fontFamily: 'roboto',
    margin: '19px 0 -2px 10px',
    // marginBottom: '0px',
  },
})
