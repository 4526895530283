import './App.css'
// import { Canvas } from '@react-three/fiber'
// import Skybox from './core/Skybox'
// import Hud from './context/starter/Hud'
// // import Pearl from './context/starter/Ball'
// import { Suspense } from 'react'
// import CameraController from './core/camera/CameraController'
// import { Perf } from 'r3f-perf'
// import LoadingManager from './context/LoadingManager'
import { App_3dbuilder } from './framework/3d-builder/App_3dbuilder'
import { Canvas } from '@react-three/fiber'
import Skybox from './core/Skybox'
// import Hud from './context/starter/Hud'
import { Suspense } from 'react'
import CameraController from './core/camera/CameraController'
import { Perf } from 'r3f-perf'
import { gaConfig, logEnabled } from './constants'
import { fHDRI } from './files'
import { activateGA } from './helper/google-analytics'
import BlockMesh from './context/models/BlockMesh'
import HudSystem from './core/HudSystem'
import { Lights } from './framework/3d-builder/core/3d/Lights'
import { Reveal } from './core/Reveal'
import { designerEnabled } from './framework/3d-builder/settings'

activateGA(gaConfig.code)
// Styleguide - https://github.com/airbnb/javascript - MUST READ
// Vault v1.0
// - Added eslint enforcing
// - Added prettier enforcing
// - Added Panorama Viewer, change the App.js to ./framework/panorama-viewer/App.js

// Contact Admin to enable CI/CD on your branch

// Use Google Chrome & Firefox simultaneously for any testing

function App() {
  return (
    <>
      <App_3dbuilder />
      {/* {designerEnabled && <Perf headless />} */}
      {/* <Canvas flat dpr={[1, 1.5]}>
        <Suspense fallback={null}>
          <Skybox hdri={fHDRI} />
        </Suspense>
        
        <CameraController />
        <axesHelper scale={10} />
        <BlockMesh />
      </Canvas>
      {/* <Hud enabled={true} logEnabled={logEnabled} /> */}
      <Reveal />
      <HudSystem enabled={true} logEnabled={logEnabled} />
    </>
  )
}

export default App
