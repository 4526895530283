import { useSpring } from '@react-spring/core'
import { css, StyleSheet } from 'aphrodite'
import { useAppState, useCustomHistory } from '../../stores'
import { animated as a } from '@react-spring/web'
export const Homepage = () => {
  const customHistory = useCustomHistory()
  const start = () => {
    customHistory.setParams({ page: 'levels' })
  }
  const appState = useAppState()
  const { opacity } = useSpring({
    from: { opacity: 1 },
    opacity: appState.page === 'homepage' ? 1 : 0,
  })
  return (
    <a.div
      style={{ opacity, pointerEvents: appState.page === 'homepage' ? 'auto' : 'none' }}
      className={css(styles.container)}
    >
      <h1>Level Designer</h1>
      <button onClick={start}>start</button>
    </a.div>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'blue',
  },
})
