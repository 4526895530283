// import React from 'react'

import { useFrame, useThree } from '@react-three/fiber'
import { useControls } from 'leva'
import { useEffect, useMemo } from 'react'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import { SAOPass } from 'three/examples/jsm/postprocessing/SAOPass'
import { useAppState } from '../framework/3d-builder/stores'

/**\
 * 
    export interface SAOPassParams {
    output: OUTPUT;
    saoBias: number;
    saoIntensity: number;
    saoScale: number;
    saoKernelRadius: number;
    saoMinResolution: number;
    saoBlur: boolean;
    saoBlurRadius: number;
    saoBlurStdDev: number;
    saoBlurDepthCutoff: number;
}
 */
export default function CustomSAO({ roomName }) {
  const { gl, scene, camera, size } = useThree()
  const {
    room,
    currentContent: { name },
  } = useAppState()
  const saoValues = {
    psur: {
      saoBias: 0,
      saoIntensity: 0.05,
      saoScale: 50,
      saoKernelRadius: 4,
      saoMinResolution: 0,
      saoBlur: true,
    },
    valuechain: {
      saoBias: -1,
      saoIntensity: 0.02,
      saoScale: 40,
      saoKernelRadius: 2,
      saoMinResolution: 0,
      saoBlur: true,
    },
    psurfar: {
      saoBias: -1,
      saoIntensity: 0.02,
      saoScale: 40,
      saoKernelRadius: 2,
      saoMinResolution: 0,
      saoBlur: true,
    },
  }
  const { saoGUI, saoBias, saoIntensity, saoScale, saoKernelRadius, saoMinResolution, saoBlur } =
    useControls({
      saoGUI: { value: false },
      saoBias: {
        value: -1,
        step: 0.01,
      },
      saoIntensity: {
        value: 0.02,
        step: 0.005,
      },
      saoScale: {
        value: 40,
        step: 1,
      },
      saoKernelRadius: {
        value: 2,
        step: 1,
      },
      saoMinResolution: {
        value: 0,
        step: 0.01,
      },
      saoBlur: {
        value: true,
      },
    })
  const composer = useMemo(() => {
    const composer = new EffectComposer(gl)
    composer.addPass(new RenderPass(scene, camera))
    const saoPass = new SAOPass(scene, camera, false, true)
    if (saoGUI) {
      saoPass.params.saoBias = saoBias
      saoPass.params.saoIntensity = saoIntensity
      saoPass.params.saoScale = saoScale
      saoPass.params.saoKernelRadius = saoKernelRadius
      saoPass.params.saoMinResolution = saoMinResolution
      saoPass.params.saoBlur = saoBlur
    } else if (name === 'psuroverview') {
      saoPass.params = { ...saoPass.params, ...saoValues.psurfar }
    } else {
      saoPass.params = { ...saoPass.params, ...saoValues[room.name] }
    }
    // // console.log('setting composer')
    composer.addPass(saoPass)
    // useEffect(()=>{

    // },[saoBias,saoIntensity,saoScale,saoKernelRadius,saoMinResolution,saoBlur])
    return composer
  }, [saoBias, saoIntensity, saoScale, saoKernelRadius, saoMinResolution, saoBlur, room.name, name])
  useEffect(() => void composer.setSize(size.width, size.height))
  // useFrame((_, delta) => composer.render(delta), name !== 'psur' ? 1 : undefined)
  useFrame((_, delta) => composer.render(delta), 1)
  return null
}
