import create from 'zustand'
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min'

export const useTempStore = create((set) => ({
  currentValue: 0,
  setCurrentValue: (index) => set(() => ({ currentValue: index })),
}))

// 0 - box1 clicked
// 1 - box2 clicked
// 2 - box3 clicked

export const useLoader = create((set) => ({
  // everythingLoaded : false,
  isDone: false,
  setIsDone: (bool) => set(() => ({ isDone: bool })),
  // setEverythingLoaded : (bool) => set(state => ({everythingLoaded : bool}))
}))

export const useTween = create((set) => ({
  activeTweenPoint: null,
  tweenActive: false,

  tweenDuration: 2000,
  tweenInterpolation: TWEEN.Easing.Quadratic.InOut,
  tweenName: 'default',

  startTween: (
    tweenPoint,
    tweenName,
    tweenDuration = 3200,
    interp = TWEEN.Easing.Quadratic.InOut
  ) => {
    set(() => ({
      activeTweenPoint: tweenPoint,
      tweenActive: true,
      tweenDuration: tweenDuration,
      tweenInterpolation: interp,
      tweenName: tweenName,
    }))
  },

  tweenFinished: () => {
    set(() => ({
      tweenActive: false,
    }))
  },
}))
