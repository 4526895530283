import { OrbitControls, TransformControls } from '@react-three/drei'
import { useThree, useFrame } from '@react-three/fiber'
import { useControls } from 'leva'
import React, { useEffect, useRef } from 'react'
import * as THREE from 'three'
import {
  CineonToneMapping,
  DirectionalLightHelper,
  LinearToneMapping,
  NoToneMapping,
  PCFShadowMap,
  Vector2,
} from 'three'
import { shadowResolutions } from '../../../../constants'
import { useAppState } from '../../stores'

const lightParams = {
  base: {
    radius: 1.18,
    bias: -0.0002,
    normalBias: 0.12,
    position: [27, 110, 8],
    pointIntensity: 0.8,
    ambientIntensity: 0.45,
    color: '#f5f1da',
  },
  psur: {
    radius: 1.18,
    bias: -0.0002,
    normalBias: 0.12,
    position: [6, 14, 8],
    pointIntensity: 0.2,
    ambientIntensity: 0.3,
    color: '#fffefe',
  },
  valuechain: {
    radius: 1.18,
    bias: -0.0002,
    normalBias: 0.12,
    position: [6, 14, 8],
    pointIntensity: 0.2,
    ambientIntensity: 0.3,
    color: '#fffefe',
  },
}

export const Lights = ({ roomName }) => {
  const light = useRef()
  const { scene, gl } = useThree()
  const transform = useRef()
  const helper = useRef()
  const lightRight = useRef()
  const lightNeg = useRef()
  // const { room, ...useApp } = useAppState()
  const { lightGUI, ...controls } = useControls({
    lightGUI: false,
    top: { value: 377, step: 10 },
    left: { value: 768, step: 10 },
    near: { value: 0 },
    far: { value: 1000 },
    normalBias: {
      value: 0.3,
      step: 0.01,
    },
    pointInt: {
      value: 0.3,
      step: 0.05,
    },

    ambientInt: {
      value: 0.3,
      step: 0.05,
    },
    pointInt: { value: 0.3, step: 0.05 },
    shadowBias: { value: -20, step: 0.1 },
    ambientInt: { value: 0, step: 0.05 },
    tonemapping: { value: 1, step: 0.05, min: 0, max: 2 },
    shadowRadius: { value: 1.18, step: 0.05 },
    camposition: {
      value: { x: 6, y: 14, z: 8 },
      step: 1,
    },
    pointColor: { value: '#ffffff' },
    normalBias: { value: 0.12, step: 0.005 },
  })
  useEffect(() => {
    gl.toneMapping = LinearToneMapping
  }, [])
  useEffect(() => {
    gl.toneMappingExposure = controls.tonemapping
  }, [controls.tonemapping])
  useEffect(() => {
    //         // light.current.shadow.mapSize.set( 2**controls.X, 2**controls.X );
    light.current.shadow.mapSize.set(
      Math.pow(2, shadowResolutions[roomName]),
      Math.pow(2, shadowResolutions[roomName])
    )
  }, [roomName])
  useEffect(() => {
    if (lightGUI) gl.shadowMap.autoUpdate = true
    // else gl.shadowMap.autoUpdate = false
  }, [lightGUI])
  return (
    <>
      {
        <directionalLight
          ref={light}
          // color={useApp.roomName === 'psur' ? 'white' : '#fff4e2'}
          // position={
          //   useApp.roomName === 'psur'
          //     ? Object.values(controls.psurPosition)
          //     : Object.values(camposition)
          // }
          // shadow-bias={(-controls.psurshadowbias * 1) / 100000}
          // shadow-normalBias={useApp.roomName === 'psur' ? controls.psurnormalbias : nb}
          // intensity={useApp.roomName === 'psur' ? controls.psurIntensity : pointInt}
          castShadow
          shadow-camera-near={lightGUI ? controls.near : 0}
          shadow-camera-far={lightGUI ? controls.far : 1000}
          shadow-camera-left={lightGUI ? -controls.left : -768}
          shadow-camera-right={lightGUI ? controls.left : 768}
          shadow-camera-top={lightGUI ? controls.top : 377}
          shadow-camera-bottom={lightGUI ? -controls.top : -377}
          shadow-bias={lightGUI ? controls.shadowBias / 100000 : lightParams[roomName]?.bias}
          shadow-normalBias={lightGUI ? controls.normalBias : lightParams[roomName]?.normalBias}
          shadow-radius={lightGUI ? controls.shadowRadius : lightParams[roomName]?.radius}
          position={
            lightGUI ? Object.values(controls.camposition) : lightParams[roomName]?.position
          }
          intensity={lightGUI ? controls.pointInt : lightParams[roomName]?.pointIntensity}
          color={lightGUI ? controls.pointColor : lightParams[roomName]?.color}
        />
      }
      <ambientLight
        intensity={lightGUI ? controls.ambientInt : lightParams[roomName]?.ambientIntensity}
        color={'0672fe'}
      />
      {/* <ambientLight
        // intensity={lightGUI ? controls.ambientInt : lightParams[roomName]?.ambientIntensity}
        intensity={1}
        color={'0672fe'}
      /> */}
    </>
  )
}
