import { StyleSheet, css } from 'aphrodite'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { colors, font } from '../../theme'

export default function StandardHeaderWithDescription({
  align,
  header,
  headerFontSize,
  headerLineHeight,
  descriptionFontSize,
  descriptionLineHeight,
}) {
  const [alignmentState, setAlignmentState] = useState('center')

  useEffect(() => {
    setAlignmentState(align)
  }, [align])

  return (
    <>
      {/*  <div className={css(styles.container)} style={{ textAlign: alignmentState }}> */}
      <div
        className={css(styles.header)}
        style={{
          fontFamily: font.normal,
          color: colors.trueBlue,
        }}
      >
        {header.title}
      </div>
      <div
        className={css(styles.description)}
        style={{
          fontFamily: font.normal,
          color: colors.trueBlue,
        }}
      >
        {header.description}
      </div>
      {/* </div> */}
    </>
  )
}

StandardHeaderWithDescription.propTypes = {
  align: PropTypes.string,
  header: PropTypes.object,
  headerFontSize: PropTypes.number,
  headerLineHeight: PropTypes.string,
  descriptionFontSize: PropTypes.number,
  descriptionLineHeight: PropTypes.string,
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  header: {
    letterSpacing: 0.2,
    whiteSpace: 'pre-line',
    fontWeight: 'bold',
    fontSize: '3.6vh',
    lineHeight: '3.5vh',
    marginTop: '1vh',
  },
  description: {
    marginTop: '2vh',
    whiteSpace: 'pre-line',
    fontSize: '2.05vh',
    lineHeight: '2.1vh',
  },
})
