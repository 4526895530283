import React from 'react'
import ButtonInformation from './ButtonInformation'
import CenterButton from './CenterButton'
import PropTypes from 'prop-types'

export default function CenterButtonWithInformation({
  align,
  buttonObject,
  buttonWidth,
  buttonHeight,
  onClick,
}) {
  return (
    <div>
      <ButtonInformation align={align} buttonObject={buttonObject} />
      <CenterButton
        buttonObject={buttonObject}
        buttonWidth={buttonWidth}
        buttonHeight={buttonHeight}
        onClick={onClick}
      />
    </div>
  )
}

CenterButtonWithInformation.propTypes = {
  align: PropTypes.string,
  buttonObject: PropTypes.array,
  buttonHeight: PropTypes.string,
  buttonWidth: PropTypes.string,
}
