import { animated as a } from '@react-spring/web'
export const HotspotSVG1 = (props) => {
  // const { transform } = useSprin
  return (
    <a.svg
      {...props}
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20.8438" cy="20" r="19" fill="white" stroke="#001965" stroke-width="2" />
      <path
        d="M20.8438 13.5C21.6031 13.5 22.2188 12.8844 22.2188 12.125C22.2188 11.3656 21.6031 10.75 20.8438 10.75C20.0844 10.75 19.4688 11.3656 19.4688 12.125C19.4688 12.8844 20.0844 13.5 20.8438 13.5Z"
        fill="#001965"
        stroke="#001965"
      />
      <path d="M20.8438 28.75V16.5" stroke="#001965" stroke-width="2.5" />
    </a.svg>
  )
}

export const HotspotSVG2 = (props) => {
  return (
    <a.svg
      {...props}
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20.043" cy="20" r="19" fill="#E1E1E1" stroke="#565656" stroke-width="2" />
      <path
        d="M20.043 13.5C20.8024 13.5 21.418 12.8844 21.418 12.125C21.418 11.3656 20.8024 10.75 20.043 10.75C19.2836 10.75 18.668 11.3656 18.668 12.125C18.668 12.8844 19.2836 13.5 20.043 13.5Z"
        fill="#565656"
        stroke="#565656"
      />
      <path d="M20.043 28.75V16.5" stroke="#565656" stroke-width="2.5" />
    </a.svg>
  )
}
