import { css, StyleSheet } from 'aphrodite'
import React from 'react'
import { globalStyles } from '../../framework/panorama-viewer/context/GlobalStyles'
import { colors, font } from '../../theme'
import PropType from 'prop-types'
import ForwardArrow from '../assets/icons/ForwardArrow'
import { useLocation } from 'wouter'
import { getNewLocation } from '../../helper/routes-helpers'

export default function CenterButton({
  buttonObject,
  buttonHeight,
  buttonWidth = '10vw',
  onClick,
}) {
  const [location, setLocation] = useLocation()

  function onButtonClickState() {
    // console.log('location before', location)
    // setLocation(
    //   location +
    //     (location === '/'
    //       ? buttonObject[0].target.slice(1, buttonObject[0].target.length)
    //       : buttonObject[0].target)
    // )
    onClick ? onClick() : setLocation(getNewLocation(location, buttonObject[0].target))
  }

  return buttonObject[0].title ? (
    <div className={css(styles.container)}>
      <button
        onClick={() => {
          onButtonClickState()
        }}
        className={css(styles.button)}
        style={{ backgroundColor: colors.trueBlue, height: buttonHeight, width: buttonWidth }}
      >
        <div className={css(styles.title)} style={{ color: colors.white, fontFamily: font.normal }}>
          <span>{buttonObject[0].title}</span>
        </div>
        <div className={css(styles.icon)}>
          <ForwardArrow />
        </div>
      </button>
    </div>
  ) : null
}

CenterButton.propTypes = {
  buttonObject: PropType.array,
  buttonWidth: PropType.string,
  buttonHeight: PropType.string,
}

const styles = StyleSheet.create({
  container: {
    textAlign: 'center',
    margin: '1.5vh',
  },
  button: {
    outline: 0,
    // padding: '1.3vh',
    padding: '0 0.5vw 0 0.5vw',

    border: 'none',
    // width: '10vw',
    height: '6vh',
    pointerEvents: 'auto',
    cursor: 'pointer',

    ':active': {
      transform: 'scale(1.1)',
    },
  },
  title: {
    float: 'left',
    width: '80%',
    fontSize: '1.9vh',
    whiteSpace: 'nowrap',
    lineHeight: '6vh',
  },
  icon: {
    textAlign: 'right',
    float: 'right',
    width: '20%',
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    // display: 'inline-flex',
  },
})
