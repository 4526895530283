import React from 'react'
import { Color_AutoVRseBlue, Color_AutoVRseLightBlue } from '../../../../theme'

export default function LoadingSpinner() {
  return (
    <div id="container">
      <svg viewBox="0 0 100 100">
        <defs>
          <filter id="shadow">
            <feDropShadow dx="0" dy="0" stdDeviation="1.5" floodColor={Color_AutoVRseLightBlue} />
          </filter>
        </defs>
        <circle
          id="spinner"
          style={{
            fill: 'transparent',
            stroke: Color_AutoVRseBlue,
            strokeWidth: 7,
            strokeLinecap: 'round',
            filter: 'url(#shadow)',
          }}
          cx="50"
          cy="50"
          r="45"
        />
      </svg>
    </div>
  )
}
