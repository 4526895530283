import { css, StyleSheet } from 'aphrodite'
import React, { useEffect } from 'react'
import { useState } from 'react'
export const ListForm = ({ possibleValues, selected, set, objKey, onChange, onReset }) => {
  const [list, setList] = useState([])
  const [select, setSelect] = useState('')
  useEffect(() => {
    set((state) => {
      let temp = { ...state }
      temp[objKey] = [...list]
    })
  }, [list])
  return (
    <>
      <div className={css(styles.itemsContainer)}>
        {selected?.map((item, i) => (
          <p className={css(styles.item)} key={i}>
            {item}
          </p>
        ))}
      </div>
      <select onChange={onChange}>
        <option value="">default</option>
        {possibleValues?.map((item, i) => (
          <option key={i} value={item}>
            {item}
          </option>
        ))}
      </select>
      <button style={{ fontSize: '0.7em' }} onClick={onReset}>
        reset
      </button>
    </>
  )
}

const styles = StyleSheet.create({
  itemsContainer: {
    display: 'flex',
    fontSize: 'Arial',
    fontSize: '0.7em',
  },
  item: {
    border: '1px solid white',
  },
})
