import React, { useEffect, useState } from 'react'
import { applyProps, Canvas } from '@react-three/fiber'
// import { CustomHistory } from './core/routes/CustomHistory'
import { Environment } from './environment/Environment'
import { UIManager } from './UI/UIManager'
import { Designer } from './designer/Designer'
import { useAppState } from './stores'
import { RoutesManager } from './core/routes/RoutesManager'
import { LoadingManager } from './core/3d/LoadingManager'
import { Leva } from 'leva'
import { designerEnabled } from './settings'
import { Lights } from './core/3d/Lights'
import { MainScene } from './environment/MainScene'
import { Perf } from 'r3f-perf'
import { Sky } from './environment/Sky'
import { Performance } from './core/3d/Performance'

export const App_3dbuilder = () => {
  const useApp = useAppState()
  const [mouseDown, setMouse] = useState()
  useEffect(() => {
    useApp.fetchRooms()
  }, [])
  return (
    useApp.allRooms && (
      <div
        onMouseDown={() => setMouse(true)}
        onMouseUp={() => setMouse(false)}
        style={{
          position: 'absolute',
          cursor:
            useApp.currentContent.name === 'nndc' ? (mouseDown ? 'grabbing' : 'grab') : 'auto',
          top: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
        }}
      >
        {/* <CustomHistory /> */}
        <RoutesManager />
        <Canvas colorManagement shadows>
          {/* {useApp.room?.name && <Lights />} */}
          <Environment />
          {/* <Performance /> */}
          {/* {designerEnabled && <Perf />} */}
          {/* <MainScene /> */}
          {/* <Box /> */}
          {/* <Sky /> */}
        </Canvas>
        <LoadingManager />
        <Designer />
        <UIManager />
        <Leva hidden={!designerEnabled} />
      </div>
    )
  )
}
