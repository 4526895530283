import { config, useSpring } from '@react-spring/core'
import { css, StyleSheet } from 'aphrodite'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'wouter'
import { hud } from '../constants'
import CenterButtonWithInformation from '../context/hud/CenterButtonWithInformation'
import { colors, font } from '../theme'
import { animated as a } from '@react-spring/web'
import Lottie from 'react-lottie'
import clouds from '../context/assets/lotties/Intro/Reveal.json'

export const Reveal = ({ cardObject }) => {
  const [location, setLocation] = useLocation()
  const [anim, setAnim] = useState(false)
  const [mount, setMount] = useState(true)
  const lottieRef = useRef()
  const { opacity } = useSpring({
    from: { opacity: 0 },
    opacity: location === '/' ? 1 : 0,
    config: config.slow,
    // delay: location !== '/' ? 2000 : 0,
  })
  const timeout = useRef()
  useEffect(() => {
    if (location === '/nndc') {
      lottieRef.current?.play()
      timeout.current = setTimeout(() => setMount(false), [4500])
    } else if (location === '/') {
      timeout.current && clearTimeout(timeout.current)
      setMount(true)
    } else {
      setMount(false)
    }
  }, [location])

  const defaultOptions = {
    loop: false,
    autoplay: false,

    animationData: clouds,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  return mount ? (
    <a.div style={{}} className={css(styles.container)}>
      <Lottie
        style={{ pointerEvents: 'none' }}
        ref={lottieRef}
        options={defaultOptions}
        isStopped={true}
      />
      <a.div
        style={{
          opacity,
          position: 'absolute',
          zIndex: 110,
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
        }}
      >
        <h1 className={css(styles.heading)}>
          Welcome to
          <br />
          Novo Nordisk Development City
        </h1>
        <p className={css(styles.p)}>
          We develop innovative medicines that improve lives for people with serious chronic
          diseases, and we aspire to be the best in the world at doing this.
        </p>
        {/* <div>
          
        </div> */}
        <div style={{ marginTop: '7%', pointerEvents: 'none' }}>
          <CenterButtonWithInformation
            align={'center'}
            buttonObject={hud['/'].button}
            buttonWidth={'13.8vw'}
            buttonHeight={'auto'}
            // onClick={() => setAnim(true)}
          />
        </div>
      </a.div>
    </a.div>
  ) : null
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    // backgroundColor: 'red',
    top: 0,
    left: 0,
    textAlign: 'center',
    fontFamily: font.normal,
    color: colors.trueBlue,
    zIndex: 100,
    pointerEvents: 'none',
  },
  heading: {
    fontSize: '4.4vh',
    marginTop: '25vh',
  },
  p: {
    fontSize: '2.2vh',
    width: '30%',
    marginLeft: '35%',
    textAlign: 'center',
  },
})
