import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { Homepage } from './fullscreen/Homepage'

export const UIManager = () => {
  return (
    <div className={css(styles.container)}>
      <Homepage />
    </div>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    pointerEvents: 'none',
    zIndex: 100,
  },
})
