import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, css } from 'aphrodite'
import { globalStyles } from '../../framework/panorama-viewer/context/GlobalStyles'
import Branding from './Branding'
import StandardHeaderWithDescription from './StandardHeaderWithDescription'
import NavigationList from './NavigationList'
import Breadcrumbs from './Breadcrumbs'

const alignment = 'left'

export default function CardType2({ visibility, cardObject }) {
  return (
    <div className={css(visibility ? styles.container : globalStyles.norender)}>
      <div className={css(styles.brandingContainer)}>
        <Branding />
      </div>
      {!cardObject.noBreadCrumbs && (
        <div className={css(styles.breadcrumbsContainer)}>
          <Breadcrumbs />
        </div>
      )}
      <div className={css(styles.scrollCard)}>
        <div className={css(styles.headerWithDescriptionContainer)}>
          <StandardHeaderWithDescription
            align={alignment}
            header={cardObject.header}
            descriptionFontSize={14}
            descriptionLineHeight="16px"
          />
        </div>
      </div>
      <div className={css(styles.scrollCardBottom)}>
        <div className={css(styles.navigationListContainer)}>
          <NavigationList
            listObject={cardObject.list}
            align={alignment}
            buttonTextFontSize={16}
            buttonTextLineHeight={'18.4px'}
            enabledItem={
              cardObject.enabledItem?.index !== 'undefined' ? cardObject.enabledItem?.index : 'all'
            }
          />
        </div>
      </div>
    </div>
  )
}

CardType2.propTypes = {
  visibility: PropTypes.bool,
  cardObject: PropTypes.object,
}

const styles = StyleSheet.create({
  container: {
    // height: '100vh',
    // display: 'block',
  },
  brandingContainer: {
    height: '12vh',
    padding: '1.5vw',
  },
  breadcrumbsContainer: {
    height: '5.5vh',
    paddingLeft: '1.5vw',
    marginBottom: '1.5vh',
  },
  headerWithDescriptionContainer: {
    // height: '41vh',
    padding: '0vw 1.5vw',
    fontSize: '28px',
    lineHeight: '32.2px',
    '@media(max-width:800px)': {
      fontSize: '20px',
      lineHeight: '20px',
    },
  },
  navigationListContainer: {
    height: '20vh',
  },
  scrollCard: {
    overflowY: 'auto',
    pointerEvents: 'auto',
    height: '52vh',
    marginRight: '4px',
    '::-webkit-scrollbar': {
      width: '0.5vw',
      margin: '4.8vh',
      background: 'rgb(48, 81, 184)',
      borderRadius: '10px',
    },
    '::-webkit-scrollbar-thumb': {
      background: 'rgba(242, 242, 242, 0.7)',
      borderRadius: '20px',
      height: '2.4vh',
    },
  },
  scrollCardBottom: {
    overflowY: 'auto',
    pointerEvents: 'auto',
    height: '35vh',
    marginRight: '4px',
    '::-webkit-scrollbar': {
      width: '0.4rem',
      margin: '4.8vh',
      background: 'rgba(61, 61, 61, 0.8)',
      borderRadius: '10px',
    },
    '::-webkit-scrollbar-thumb': {
      background: 'rgba(242, 242, 242, 0.15)',
      borderRadius: '20px',
      height: '2.4vh',
    },
  },
})
