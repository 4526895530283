import React, { useMemo, useState } from 'react'
import { useLocation } from 'wouter'
import { useAppState } from '../../framework/3d-builder/stores'
import { colors, font } from '../../theme'

export const BackButton = (props) => {
  const { room } = useAppState()
  const [location, setLocation] = useLocation()
  const shouldShow = useMemo(() => {
    return room?.name === 'psur'
  }, [room?.name])
  const onClick = () => {
    // setLocation('/nndc/slcm/gra/radb')
    // if (room.name === 'valuechain') setLocation(location.split('/').slice(0, location.split('/').length - 2).join('/'))
    // history.back()
    // console.log(location)
    if (room.name === 'psur') {
      if (location === '/psur') {
        return setLocation('/valuechain')
      } else {
        return setLocation('/psur')
      }
    } else if (room.name === 'valuechain') {
      if (location === '/valuechain/radb') {
        return setLocation('/nndc')
      } else {
        return setLocation('/valuechain/radb')
      }
    } else if (room.name === 'base') {
      if (location === '/nndc') {
        return setLocation('/')
      } else {
        return setLocation('/nndc')
      }
    }
    return history.back()
  }
  return (
    <button
      {...props}
      onClick={onClick}
      style={{
        lineHeight: '2.5vh',
        height: '2.3vh',
        backgroundColor: 'rgba(0,0,0,0)',
        border: 'none',
        boxShadow: 'none',
        display: 'flex',
        position: 'absolute',
        // left: '24vw',
        right: '1vw',
        top: '4vh',
        pointerEvents: 'auto',
        cursor: 'pointer',
      }}
    >
      <svg
        style={{ margin: 0 }}
        // width="9"
        height="100%"
        viewBox="0 0 9 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8.25 1L2 5.99905L8.25 11" stroke="#001965" stroke-width="1.5" />
      </svg>
      <p
        style={{
          fontFamily: font.normal,
          margin: 0,
          marginLeft: '0.4vw',
          fontSize: '2.2vh',
          lineHeight: '2.2vh',
          color: colors.trueBlue,
        }}
      >
        Back
      </p>
    </button>
  )
}
