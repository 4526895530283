import { css, StyleSheet } from 'aphrodite'
// import devContent from './../dev.json'
import PropTypes from 'prop-types'

export default function HudManager(props) {
  return (
    <>
      {/* <div className={css(styles.containerFullscreen)}>{props.fullscreen}</div> */}
      <div
        className={css(styles.containerLeft, styles.noInteration, styles.absolute)}
        style={{ backgroundColor: props.backgroundcolor }}
      >
        {props.left}
      </div>
      <div className={css(styles.containerCenter, styles.noInteration, styles.absolute)}>
        {props.center}
      </div>
      <div className={css(styles.containerRight, styles.noInteration, styles.absolute)}>
        {props.right}
      </div>
    </>
  )
}

HudManager.propTypes = {
  fullscreen: PropTypes.element,
  left: PropTypes.element,
  right: PropTypes.element,
  center: PropTypes.element,
  backgroundcolor: PropTypes.string,
}

const styles = StyleSheet.create({
  noInteration: {
    pointerEvents: 'none',
  },
  container: {
    position: 'absolute',
    // left: 0,
    top: 0,
    left: 0,
    // bottom: 0,
    width: '100%',
    height: '100%',
    zIndex: 5,
  },
  containerFullscreen: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    // backgroundColor: 'red',
  },
  absolute: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    zIndex: 5,
    // margin: devContent.GridSystem.gutter,
  },
  containerLeft: {
    left: 0,
    width: '30vw',
    float: 'left',
    // '@media(max-width:800px)': {
    //   width: '37vw',
    // },
    // '@media(min-width:802px) and (max-width:1024px)': {
    //   width: '37vw',
    // }
  },
  containerRight: {
    right: 0,
    float: 'right',
    width: '30vw',
  },
  containerCenter: {
    left: '30vw',
    right: '30vw',
    textAlign: 'center',
  },
})
