import React, { useEffect, useState } from 'react'
import { useSpring } from '@react-spring/core'
import { animated as a } from '@react-spring/web'
export const Renders = ({ render }) => {
  const [local, set] = useState()
  useEffect(() => {
    if (render) set(render)
  }, [render])
  const spring = useSpring({
    opacity: render ? 1 : 0,
  })
  return (
    <a.img
      style={{
        position: 'absolute',
        width: '100vw',
        left: 0,
        top: 0,
        opacity: spring.opacity,
        pointerEvents: 'none',
        zIndex: 0,
      }}
      width="100%"
      src={'./' + local}
    />
  )
}
