import { css, StyleSheet } from 'aphrodite'
import React from 'react'
import { globalStyles } from '../../framework/panorama-viewer/context/GlobalStyles'
import { colors, font } from '../../theme'
import PropType from 'prop-types'
import ForwardArrow from '../assets/icons/ForwardArrow'
import { useLocation } from 'wouter'
import { getNewLocation } from '../../helper/routes-helpers'

export default function RightButton({ buttonObject, buttonWidth, buttonHeight }) {
  const [location, setLocation] = useLocation()

  function OnButtonClick() {
    // setLocation(location + buttonObject[0].target)
    setLocation(getNewLocation(location, buttonObject[0].target))
  }

  return (
    <div className={css(styles.container)}>
      <button
        onClick={() => {
          OnButtonClick()
        }}
        className={css(styles.button)}
        style={{ backgroundColor: colors.trueBlue, width: buttonWidth, height: buttonHeight }}
      >
        <div className={css(styles.title)} style={{ color: colors.white, fontFamily: font.normal }}>
          {buttonObject[0].title}
        </div>
        <div className={css(styles.icon)}>
          <ForwardArrow />
        </div>
      </button>
    </div>
  )
}

RightButton.propTypes = {
  buttonObject: PropType.array,
  buttonHeight: PropType.string,
  buttonWidth: PropType.string,
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  button: {
    outline: 0,
    // padding: 10,
    border: 'none',
    pointerEvents: 'auto',
    cursor: 'pointer',
    height: '6vh',
    padding: 0,
    paddingLeft: '0.5vw',
    // '@media(max-width:800px)':{
    //   padding: 4,
    // }
  },
  title: {
    float: 'left',
    width: '80%',
    fontSize: '1.9vh',
    display: 'inline-flex',
    lineHeight: '6vh',
  },
  icon: {
    textAlign: 'right',
    float: 'right',
    width: '20%',
    display: 'inline-flex',
    position: 'relative',
    top: '50%',
    transform: 'translateY(-58%)',
  },
})
