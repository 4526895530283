import { useFrame } from '@react-three/fiber'
import React, { useEffect, useState } from 'react'
import { useAppState, useCustomCamera, useLoadingManager } from '../../stores'
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min'
import { object } from 'prop-types'
import { Loader } from '@react-three/drei'

const Tween = ({ object, to, time = 2000, instant = false }) => {
  const { setisTweening } = useCustomCamera()
  useEffect(() => {}, [object.target])
  useEffect(() => {
    // if (instant) {
    //   object.set(...Object.values(to))
    //   setisTweening(false)
    // } else {

    let tween = new TWEEN.Tween(object)
      .to(to, !instant ? time : 1000)
      .onComplete(() => setisTweening(false))
      .easing(TWEEN.Easing.Quadratic.InOut)
    tween.start()
    return () => tween.stop()
    // }
  }, [to])
  useFrame(() => {
    TWEEN.update()
  })
  return null
}
export const TweenManager = React.memo(() => {
  const { currentContent, allRooms } = useAppState()
  const { camera, controls, setisTweening } = useCustomCamera()
  const [localTo, setLocalTo] = useState({
    position: { ...camera.position },
    lookAt: { ...controls.target },
  })
  const [time, setTime] = useState(100)
  const loader = useLoadingManager()
  useEffect(() => {}, [])
  useEffect(() => {
    // console.log('curr content changed', currentContent.name)
    // console.log('curr content', currentContent)
    if (currentContent) {
      setisTweening(true)

      if (currentContent.view) {
        let currentView = JSON.parse(currentContent.view)
        setTime(2000) // remove this for lafs like movement ig
        setLocalTo({
          position: currentView.position,
          lookAt: currentView.lookAt,
          fov: 50,
        })
      }
    }
  }, [currentContent]) //add seperate for hotspot this way it makes sense only animate when hotspots change
  // useEffect(() => {

  //   setisTweening(true)
  //   let hotspots = allRooms.find((room) => room.name === location)?.hotspots
  //   let currentHotspotView = hotspots.find((currHotspot) => currHotspot.id === hotspot)?.view
  //   if (currentHotspotView) {
  //     currentHotspotView = JSON.parse(currentHotspotView)
  //     // const currentHotspotView = currentRoomView
  //     setTime(2000) // remove this for lafs like movement ig
  //     setLocalTo({
  //       position: currentHotspotView.position,
  //       lookAt: currentHotspotView.lookAt,
  //       fov: 50,
  //     })
  //   }

  // }, [hotspot])
  useFrame(() => {
    camera.updateProjectionMatrix()
    controls.update()
    // console.log(controls.target)
  })
  return (
    <>
      <Tween instant={loader.fadeIn} object={camera.position} to={localTo.position} time={time} />
      <Tween instant={loader.fadeIn} object={controls.target} to={localTo.lookAt} time={time} />
    </>
  )
})
