/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useGLTF } from '@react-three/drei'
import { Suspense } from 'react'

export default function BlockMesh() {
  return (
    <Suspense fallback={null}>
      <BlockMeshPrimitive />
    </Suspense>
  )
}

function BlockMeshPrimitive() {
  const gltf = useGLTF('./glb/Block_1.glb')

  return <primitive object={gltf.scene} dispose={null} />
}

useGLTF.preload('./glb/Block_1.glb')
