import React, { useEffect, useRef, useState } from 'react'
// import { useLocation } from 'wouter'
// import { useStatic } from '../stores'
import { voiceOverSource } from '../voiceOversSource'
import PropTypes from 'prop-types'
import { useLocation } from 'wouter'
import { useLoadingManager } from '../framework/3d-builder/stores'

const visitedVOs = []
export const VoiceOver = React.memo(({ url, muted }) => {
  const [audioSrc, setAudioSrc] = useState(null)
  const audio = useRef()
  const [location, setLocation] = useLocation()
  const [suspend, setSuspend] = useState(false)
  const { fadeIn } = useLoadingManager()

  useEffect(() => {
    audio.current?.pause()
    // setTimeout(() => setSuspend(true), [2200])
    // setTimeout(() => setSuspend(false), [2400])
    // console.log('fade in', fadeIn)
    let voVisitedTimeout = null
    let voPlayTimeout = null
    if (!fadeIn) {
      // console.log('voice url', url)
      setSuspend(true)
      setTimeout(() => setSuspend(false), [2000])
      if (!visitedVOs.some((x) => x === url)) {
        // visitedVOs.push(url)
        voPlayTimeout = setTimeout(() => {
          console.log('playing audio', fadeIn)
          if (!fadeIn) {
            audio.current?.play()
            voVisitedTimeout = setTimeout(() => visitedVOs.push(url), 4000) // to make a vo has been played for some time}
          }
        }, [2100])
      }
    }

    return () => {
      voVisitedTimeout && clearTimeout(voVisitedTimeout)
      voPlayTimeout && clearTimeout(voPlayTimeout)
    }
  }, [url, fadeIn])
  // useEffect(() => {
  //   if (!suspend) {
  //     if (pageObject.voiceOver != null) {
  //       if (pageObject.voiceOver.index != -1) {
  //         i = pageObject.voiceOver.index
  //         // console.log('voice over i: ' + i, voiceOverSource[i]?.source)
  //         setAudioSrc(voiceOverSource[i]?.source)
  //         // audio.current?.load()
  //         // audio.current?.play()
  //       } else {
  //         setAudioSrc(null)
  //       }
  //     } else {
  //       // audio.current.pause()
  //     }
  //   }
  // }, [suspend])

  function handleFirstPlay(event) {
    // console.log(event)
  }

  return (
    <>
      {url && !suspend ? (
        <audio
          ref={audio}
          muted={muted}
          // autoPlay
          // onPlay={(event) => {
          //   handleFirstPlay(event)
          // }}
        >
          <source src={url} type="audio/mp3" />
        </audio>
      ) : null}
    </>
  )
})

export default VoiceOver

VoiceOver.propTypes = {
  pageObject: PropTypes.object,
  playState: PropTypes.bool,
}
