import { css, StyleSheet } from 'aphrodite'
import React from 'react'


export function PsurTextLeft() {
    return (
        <div className={css(styles.container)}>
            <span className={css(styles.text1, styles.title)} >PSUR Timeline</span>
            <span className={css(styles.text2, styles.title)}>Create RASP</span>
            <span className={css(styles.text3, styles.title)}>Review PSUR</span>
            <span className={css(styles.text4, styles.title)}>EU/Global</span>
        </div>
    )
}
export  function PsurTextBottom1() {
    return (
        <div className={css(styles.container)}>
                  <span className={css(styles.text5, styles.title)}><p style={{ margin: "0" }}>Communicate</p><p style={{ margin: "0" }}>Publishing Request</p></span>
            <span className={css(styles.text6, styles.title)}><p style={{ margin: "0" }}>QC Published </p><p style={{ margin: "0" }}>Output</p></span>
            <span className={css(styles.text7, styles.title)}><p style={{ margin: "0" }}>Insert Additional </p><p style={{ margin: "0" }}>Documents</p></span>
        </div>
    )
}
export function PsurTextBottom2() {
    return (
        <div className={css(styles.container)}>
                  <span className={css(styles.text5, styles.title)}><p style={{ margin: "0" }}>Submission Request</p><p style={{ margin: "0" }}>sent to Publishing</p></span>
            <span className={css(styles.text6, styles.title)}><p style={{ margin: "0" }}>Recieved </p><p style={{ margin: "0" }}>Published Output</p></span>
        </div>
    )
}
export default function PsurTextTop() {
    return (
        <div className={css(styles.container)}>
                  <span className={css(styles.text8, styles.title)}>Distribution via email</span>
        </div>
    )
}
export function PsurTextRight() {
    return (
        <div className={css(styles.container)}>
                  <span className={css(styles.text8, styles.title)}>MU Distribution</span>
        </div>
    )
}
export function PsurTextStart() {
    return (
        <div className={css(styles.container)}>
                  <span className={css(styles.text8, styles.StartandEnd)}>START</span>
        </div>
    )
}
export function PsurTextEnd() {
    return (
        <div className={css(styles.container)}>
                  <span className={css(styles.text8, styles.StartandEnd)}>END</span>
        </div>
    )
}
const styles = StyleSheet.create({
    container: {
        display: "inline-flex"
    },
    title: {
        fontSize: 8,
        color: 'white',
        fontFamily: 'roboto',
    },
    StartandEnd: {
        fontSize: 10,
        color: 'white',
        fontFamily: 'roboto',
    },
    text1: {
        padding: '0 5px 0 35px'
    },
    text2: {
        padding: '0 35px 0 45px'
    },
    text3: {
        padding: '0 15px 0 30px'
    },
    text4:{
        padding: '0 0px 0px 45px'
    },
    text5: {
        padding: '0 15px 0 0px'
    },
    text6: {
        padding: '0 55px 0 25px'
    },
    text7: {
        padding: '0 60px 0 0px'
    },
})


