import React from 'react'

export default function StandardBackwardArrow() {
  return (
    <svg
      style={{ marginBottom: '50%' }}
      height="1.6vh"
      viewBox="0 0 5 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.55078 0.910645L1.42578 3.41017L4.55078 5.91064"
        stroke="#001965"
        strokeWidth="0.75"
      />
    </svg>
  )
}
