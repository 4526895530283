import React from 'react'
import CardType0 from './CardType0'
import PropTypes from 'prop-types'
import CardType1 from './CardType1'
import CardType2 from './CardType2'
import CardType3 from './CardType3'
import CardType4 from './CardType4'
import CardType5 from './CardType5'
import CardType6 from './CardType6'
import { Reveal } from '../../core/Reveal'
import { BackButton } from './BackButton'

function Cards({ cardObject, defaultCardObject }) {
  return (
    <>
      <BackButton />
      {cardObject.cardType === 0 && (
        <CardType0
          visibility={cardObject.cardType === 0}
          cardObject={cardObject.cardType === 0 ? cardObject : defaultCardObject}
        />
      )}
      {cardObject.cardType === 1 && (
        <CardType1
          visibility={cardObject.cardType === 1}
          cardObject={cardObject.cardType === 1 ? cardObject : defaultCardObject}
        />
      )}
      {cardObject.cardType === 2 && (
        <CardType2
          visibility={cardObject.cardType === 2}
          cardObject={cardObject.cardType === 2 ? cardObject : defaultCardObject}
        />
      )}
      {cardObject.cardType === 3 && (
        <CardType3
          visibility={cardObject.cardType === 3}
          cardObject={cardObject.cardType === 3 ? cardObject : defaultCardObject}
        />
      )}
      {cardObject.cardType === 4 && (
        <CardType4
          visibility={cardObject.cardType === 4}
          cardObject={cardObject.cardType === 4 ? cardObject : defaultCardObject}
        />
      )}
      {cardObject.cardType === 5 && (
        <CardType5
          visibility={cardObject.cardType === 5}
          cardObject={cardObject.cardType === 5 ? cardObject : defaultCardObject}
        />
      )}
      {cardObject.cardType === 6 && (
        <CardType6
          visibility={cardObject.cardType === 6}
          cardObject={cardObject.cardType === 6 ? cardObject : defaultCardObject}
        />
      )}
      {/* {cardObject.cardType === 'reveal' && <Reveal cardObject={cardObject} />} */}
    </>
  )
}

export default Cards

Cards.propTypes = {
  cardObject: PropTypes.object,
  defaultCardObject: PropTypes.object,
}
