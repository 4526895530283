import React from 'react'

function BackwardArrow() {
  return (
    <svg height="3vh" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.50755 16C4.08928 16 0.507554 12.4183 0.507554 8C0.507554 3.58172 4.08928 0 8.50755 0C12.7579 0 16.2341 3.31467 16.4922 7.50007H5.91821L7.8473 5.57099L7.14019 4.86388L4.35755 7.64652L4.004 8.00007L4.35755 8.35363L7.14019 11.1363L7.8473 10.4292L5.91821 8.50007H16.4922C16.234 12.6854 12.7579 16 8.50755 16Z"
        fill="white"
      />
    </svg>
  )
}

export default BackwardArrow
