import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, css } from 'aphrodite'
import { globalStyles } from '../../framework/panorama-viewer/context/GlobalStyles'
import Branding from './Branding'
import StandardHeaderWithDescription from './StandardHeaderWithDescription'
import NavigationList from './NavigationList'

const alignment = 'left'

export default function CardType1({ visibility, cardObject }) {
  return (
    <div className={css(visibility ? styles.container : globalStyles.norender)}>
      <div className={css(styles.brandingContainer)}>
        <Branding />
      </div>
      <div className={css(styles.scrollCard)}>
        <div className={css(styles.headerWithDescriptionContainer)}>
          <StandardHeaderWithDescription align={alignment} header={cardObject.header} />
        </div>
      </div>
      <div className={css(styles.scrollCardBottom)}>
        <div className={css(styles.navigationListContainer)}>
          <NavigationList listObject={cardObject.list} align={alignment} enabledItem={2} />
        </div>
      </div>
    </div>
  )
}

CardType1.propTypes = {
  visibility: PropTypes.bool,
  cardObject: PropTypes.object,
}

const styles = StyleSheet.create({
  // container: {
  //   height: '80vh',
  //   display: 'block',
  // },
  brandingContainer: {
    height: '12vh',
    padding: '1.5vw',
  },
  headerWithDescriptionContainer: {
    // height: '41vh',
    padding: '0vw 1.5vw',
  },
  navigationListContainer: {
    height: '20vh',
  },
  scrollCard: {
    overflowY: 'auto',
    pointerEvents: 'auto',
    height: '48vh',
    marginRight: '4px',
    '::-webkit-scrollbar': {
      width: '0.4rem',
      margin: '4.8vh',
      background: 'rgb(48, 81, 184)',
      borderRadius: '10px',
    },
    '::-webkit-scrollbar-thumb': {
      background: 'rgba(242, 242, 242, 0.15)',
      borderRadius: '20px',
      height: '2.4vh',
    },
  },
  scrollCardBottom: {
    overflowY: 'auto',
    pointerEvents: 'auto',
    height: '42vh',
    marginRight: '4px',
    '::-webkit-scrollbar': {
      width: '0.4rem',
      margin: '4.8vh',
      background: 'rgba(61, 61, 61, 0.8)',
      borderRadius: '10px',
    },
    '::-webkit-scrollbar-thumb': {
      background: 'rgba(242, 242, 242, 0.15)',
      borderRadius: '20px',
      height: '2.4vh',
    },
  },
})
