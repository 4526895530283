import { Html } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import { useControls } from 'leva'
import React, { useEffect, useRef } from 'react'
import { useAppState, useCustomCamera, useCustomHistory } from '../../stores'
import PropTypes from 'prop-types'
import { useLocation } from 'wouter'
import { config, useSpring } from '@react-spring/core'
import { animated as a } from '@react-spring/web'
import { designerEnabled } from '../../settings'
import { HotspotSVG1, HotspotSVG2 } from '../../../../svgs/HotspotSvgs'
import { css, StyleSheet } from 'aphrodite'
import { pulse } from 'react-animations'
// eslint-disable-next-line react/display-name
const getNewLocation = (oldLoc, location) => {
  return location[0] === '/' ? location : oldLoc + '/' + location
}
const Hotspot = (props) => {
  const customHistory = useCustomHistory()
  const { currentContent } = useAppState()
  const { opacity } = useSpring({
    opacity: props.helper || currentContent.suboptions.includes(props.name) ? 1 : 0,
    delay: props.helper || currentContent.suboptions.includes(props.name) ? 1500 : 0,
    config: config.slow,
  })
  useEffect(() => {
    // console.log(props.name, 'current content changed', currentContent)
  }, [currentContent])
  // const { transform } = useSpring({
  //   from: { transform: 'scale(1)' },
  //   transform: 'scale(1.4)',
  //   loop: true,
  //   // reverse: ,
  // })
  const { isTweening } = useCustomCamera()
  // const handleClick = () => {

  //   // customHistory.setParams({ hotspot: props.id })
  // }
  const style = {
    opacity,
    // transform,
    cursor: 'pointer',
    pointerEvents: currentContent.suboptions.includes(props.name) ? 'auto' : 'none',
  }
  return (
    <group position={Object.values(props.position)}>
      <Html
        zIndexRange={[1, 2]}
        zIndex={currentContent.suboptions.includes(props.name) ? 'auto' : 'none' ? 1 : 0}
        style={{ position: 'absolute', zIndex: 1, transform: 'scale(0.8) translate(-86%,-50%)' }}
      >
        {props.type == '1' ? (
          <HotspotSVG1
            onPointerDown={props.handleClick}
            className={css(styles.hotspot)}
            style={style}
          />
        ) : (
          <HotspotSVG2
            onPointerDown={props.handleClick}
            className={css(styles.hotspot)}
            style={style}
          />
        )}
      </Html>
    </group>
  )
}
export const HotspotManager = (props) => {
  const { hotspotPosition } = useCustomCamera()
  const ref = useRef()
  const { bindHp } = useControls({
    // bindHp: { value: true },
  })
  const { currentContent } = useAppState()
  const [location, setLocation] = useLocation()
  useEffect(() => {}, [hotspotPosition])
  useFrame(() => {
    // ref.current?.update()
  })
  return (
    <>
      {props.hotspots.map((hotspot, i) => {
        return (
          hotspot.position &&
          currentContent && (
            <Hotspot
              {...hotspot}
              handleClick={() => {
                setLocation(
                  location == '/'
                    ? hotspot.urlToPush || hotspot.name
                    : hotspot.urlToPush && hotspot.urlToPush[0] === '/'
                    ? hotspot.urlToPush
                    : // location[location.length-1] === '/'?
                      // location + '/' + hotspot.name
                      getNewLocation(location, hotspot.urlToPush || hotspot.name)
                )
              }}
              type={hotspot.type}
              position={JSON.parse(hotspot.position)}
              key={i}
            />
          )
        )
      })}
      {hotspotPosition && designerEnabled && (
        <Hotspot helper ref={ref} position={{ ...hotspotPosition }} type={'2'} />
      )}
    </>
  )
}

Hotspot.propTypes = {
  position: PropTypes.Object,
  id: PropTypes.String,
}
HotspotManager.propTypes = {
  hotspots: PropTypes.Array,
}

const styles = StyleSheet.create({
  hotspot: {
    ':hover': {
      transform: 'scale(1.2)',
    },
    animationName: pulse,
    animationIterationCount: 'infinite',
    animationDuration: '1s',
  },
})
