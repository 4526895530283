import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { colors, font } from '../../theme'
import { StyleSheet, css } from 'aphrodite'

export default function ButtonInformation({ buttonObject, align }) {
  const [alignmentState, setAlignmentState] = useState('center')

  useEffect(() => {
    setAlignmentState(align)
  }, [align])

  return (
    <div
      className={css(styles.information)}
      style={{ fontFamily: font.normal, color: colors.trueBlue, textAlign: alignmentState }}
    >
      {buttonObject[0].defination}
    </div>
  )
}

ButtonInformation.propTypes = {
  buttonObject: PropTypes.array,
  align: PropTypes.string,
}

const styles = StyleSheet.create({
  information: {
    fontSize: '1.5vh',
    lineHeight: '1.5vh',
    margin: 0,
  },
})
