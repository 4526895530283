import { css, StyleSheet } from 'aphrodite'
import React from 'react'
import { globalStyles } from '../../framework/panorama-viewer/context/GlobalStyles'
import { colors, font } from '../../theme'
import PropType from 'prop-types'
import ForwardArrow from '../assets/icons/ForwardArrow'
import BackwardArrow from '../assets/icons/BackwardArrow'
import useLocation from 'wouter/use-location'
import { getNewLocation } from '../../helper/routes-helpers'
import { useAppState } from '../../framework/3d-builder/stores'
export default function NextPrevButton({ buttonObject, buttonWidth, buttonHeight }) {
  const [location, setLocation] = useLocation()
  const { room } = useAppState()
  function OnPreviousButtonClick() {
    if (buttonObject[0].target && room.name === 'valuechain') return setLocation(getNewLocation(location, buttonObject[0].target))
    // setLocation(location.split('/').slice(0, location.split('/').length))
    // let temp = location.split('/').filter((x) => x !== '')
    // temp.pop()
    // setLocation('/' + temp.join('/'))
    history.back()
  }

  function OnNextButtonClick() {
    // setLocation(location + buttonObject[1].target)
    setLocation(getNewLocation(location, buttonObject[1].target))
  }

  return (
    <div className={css(styles.container)}>
      {buttonObject[0].title && <div className={css(styles.previous)}>
        <button
          onClick={() => {
            OnPreviousButtonClick()
          }}
          className={css(styles.button)}
          style={{ backgroundColor: colors.trueBlue, width: buttonWidth, height: buttonHeight }}
        >
          <div className={css(styles.backwardIcon)}>
            <BackwardArrow />
          </div>
          <div
            className={css(styles.backwardTitle)}
            style={{ color: colors.white, fontFamily: font.normal }}
          >
            {buttonObject[0].title}
          </div>
        </button>
      </div>}
      {buttonObject[1].title && <div className={css(styles.next)}>
        <button
          onClick={() => {
            OnNextButtonClick()
          }}
          className={css(styles.button)}
          style={{ backgroundColor: colors.trueBlue, width: buttonWidth }}
        >
          <div
            className={css(styles.title)}
            style={{ color: colors.white, fontFamily: font.normal }}
          >
            {buttonObject[1].title}
          </div>
          <div className={css(styles.icon)}>
            <ForwardArrow />
          </div>
        </button>
      </div>}
    </div>
  )
}

NextPrevButton.propTypes = {
  buttonObject: PropType.array,
  buttonHeight: PropType.string,
  buttonWidth: PropType.string,
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    cursor: 'pointer',
  },
  button: {
    outline: 0,
    padding: 0,
    height: '6vh',
    border: 'none',
    pointerEvents: 'auto',
    cursor: 'pointer',
    paddingLeft: '0.5vw',
    // whiteSpace: 'nowrap',
    overflow: 'hidden'
    // '@media(max-width:800px)': {
    //   height: '17vh',
    //   padding: '4px',
    // },
  },
  title: {
    float: 'left',
    width: '80%',
    textAlign: 'right',
    // background: 'red',
    fontSize: '1.9vh',
    lineHeight: '6vh',
    margin: 0,
    display: 'inline-flex',
  },
  icon: {
    textAlign: 'right',
    float: 'right',
    width: '20%',
    display: 'inline-flex',
    // lineHeight: '6vh',
    position: 'relative',
    top: '50%',
    transform: 'translateY(-53%)',
  },
  backwardTitle: {
    float: 'right',
    width: '80%',
    display: 'inline-flex',
    fontSize: '1.9vh',
    lineHeight: '6vh',
  },
  backwardIcon: {
    textAlign: 'right',
    float: 'left',
    width: '20%',
    display: 'inline-flex',
    // lineHeight: '8vh',
    position: 'relative',
    top: '50%',
    transform: 'translateY(-53%)',
  },
  previous: {
    float: 'left',
  },
  next: {
    float: 'right',
  },
})
