import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { colors, font } from '../../theme'
import { StyleSheet, css } from 'aphrodite'
import ForwardArrow from '../assets/icons/ForwardArrow'
import { useLocation } from 'wouter'
import { getNewLocation } from '../../helper/routes-helpers'
import { ListForm } from '../../framework/3d-builder/designer/ListForm'

const ListItem = ({ option, index, ...props }) => {
  const [hover, set] = useState(false)
  return (
    <div
      onMouseLeave={() => set(false)}
      onMouseEnter={() => set(true)}
      key={index}
      className={css(styles.listSpacing)}
    >
      <button
        id={option.title}
        // onClick={() => props.OnButtonClick(option, index)}
        className={css(styles.buttonStyle)}
        style={{
          textAlign: props.alignmentState,
          fontFamily: font.normal,
          color:
            index === props.enabledItem || props.enabledItem === 'all'
              ? colors.trueBlue
              : colors.lightGrey,
          // color: colors.trueBlue,
          // fontSize: props.buttonTextFontSize,
          fontSize: '2.1vh',
          lineHeight: props.buttonTextLineHeight,
          background: hover ? colors.white : 'none',
        }}
      >
        <div className={css(styles.title)}>{option.title}</div>
      </button>
      {
        <button
          id={`enter${option.title}`}
          onClick={() => props.OnEnterClick(option, index)}
          className={css(styles.enterButtonStyle)}
          style={{
            textAlign: props.alignmentState,
            fontFamily: font.normal,
            fontSize: '2.1vh',
            color:
              index === props.enabledItem || props.enabledItem === 'all'
                ? colors.white
                : colors.lightGrey,
            lineHeight: props.buttonTextLineHeight,
            background: colors.trueBlue,
            opacity: hover ? 1 : 0,
            cursor: 'pointer',
          }}
        >
          <div className={css(styles.buttonAlignment)}>
            <div className={css(styles.enterTitle)}>ENTER</div>
            <ForwardArrow />
          </div>
        </button>
      }
    </div>
  )
}
export default function NavigationList({
  listObject,
  align,
  buttonTextFontSize,
  buttonTextLineHeight,
  enabledItem,
}) {
  const [alignmentState, setAlignmentState] = useState('left')
  const [buttonClickState, setButtonClickState] = useState(false)
  const [indexValue, setIndexValue] = useState(null)
  const [location, setLocation] = useLocation()
  useEffect(() => {
    setAlignmentState(align)
  }, [align])

  function OnButtonClick(option, index) {
    if (index === enabledItem) {
      setIndexValue(option.title)
      setButtonClickState(!buttonClickState)
    }
  }

  function OnEnterClick(option, index) {
    // console.log(index)
    // setLocation(location + option.target)
    setLocation(getNewLocation(location, option.target))
    // document.getElementById(indexValue).style.background = 'none'
    // document.getElementById(`enter${indexValue}`).style.display = 'none'
  }

  useEffect(() => {
    if (indexValue !== null) {
      if (buttonClickState) {
        document.getElementById(indexValue).style.background = colors.white
        document.getElementById(`enter${indexValue}`).style.display = 'block'
      } else {
        document.getElementById(indexValue).style.background = 'none'
        document.getElementById(`enter${indexValue}`).style.display = 'none'
      }
    }
  }, [buttonClickState])

  return (
    <>
      {listObject !== null && (
        <div
          className={
            listObject.length > 5
              ? css(styles.container, styles.containerscroll)
              : css(styles.container)
          }
          style={{ textAlign: alignmentState }}
        >
          {listObject.map((option, index) => {
            return (
              <ListItem
                alignmentState={alignmentState}
                buttonTextLineHeight={buttonTextLineHeight}
                buttonTextFontSize={buttonTextFontSize}
                option={option}
                key={index}
                index={index}
                enabledItem={enabledItem}
                OnButtonClick={OnButtonClick}
                OnEnterClick={OnEnterClick}
              />
            )
          })}
        </div>
      )}
    </>
  )
}

NavigationList.propTypes = {
  listObject: PropTypes.array,
  align: PropTypes.string,
  buttonTextFontSize: PropTypes.number,
  buttonTextLineHeight: PropTypes.string,
  enabledItem: PropTypes.number,
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    padding: '1vh',
    // position: 'absolute',
    // bottom: 0,
    // height: '50vh',
    // overflow: 'auto',
    // pointerEvents: 'auto',
    // display: 'flex',
    // flexDirection: 'column-reverse',
    justifyContent: 'flex-end',
    // backgroundColor: 'red',
  },
  containerscroll: {
    height: 'auto',
    overflow: 'auto',
    pointerEvents: 'auto',
  },
  listSpacing: {
    height: '5.8vh',
    marginTop: '0.5vh',
    overflow: 'hidden',
  },
  buttonStyle: {
    pointerEvents: 'auto',
    border: 'none',
    padding: 0,
    outline: 0,
    width: '75%',
    height: '100%',
    float: 'left',
    // overflow: 'hidden',
  },
  buttonAlignment: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0px 0.2vw 0 1vw',
    // paddingLeft: '1vw',
  },
  enterButtonStyle: {
    pointerEvents: 'auto',
    border: 'none',
    padding: 0,
    outline: 0,
    width: '25%',
    height: '100%',
    float: 'right',
  },
  title: {
    paddingLeft: '0.5vw',
    whiteSpace: 'nowrap',
    // '@media(max-width:800px)': {
    //   fontSize: '13px',
    //   lineHeight: '16px',
    // },

    whiteSpace: 'nowrap',
    fontSize: '2.2vh',
    lineHeight: '5.8vh',
  },
  enterTitle: {
    fontSize: '1.8vh',
    lineHeight: '',
    // width: '70%',
    // float: 'left',
    // textAlign: 'center',
    // fontSize:'1vw',
    // display:'inline-flex'
  },
  icon: {
    marginLeft: '0.5vw',
    // marginRight: '-1vw',
    // width: '30%',
    // float: 'right',
    // textAlign: 'left',
    // display: 'inline-flex',
  },
})
