import { StyleSheet, css } from 'aphrodite'
import React from 'react'
import Branding from './Branding'
import PropTypes from 'prop-types'
import StandardHeaderWithDescription from './StandardHeaderWithDescription'
import { globalStyles } from '../../framework/panorama-viewer/context/GlobalStyles'
import RightButton from './RightButton'

const alignment = 'left'

export default function CardType4({ cardObject, visibility }) {
  return (
    <div className={css(visibility ? styles.container : globalStyles.norender)}>
      <div className={css(styles.brandingContainer)}>
        <Branding />
      </div>
      <div className={css(styles.scrollCard)}>
        <div className={css(styles.headerWithDescriptionContainer)}>
          <StandardHeaderWithDescription
            align={alignment}
            header={cardObject.header}
            descriptionFontSize={16}
            descriptionLineHeight="18.4px"
          />
        </div>
      </div>
      <div className={css(styles.rightButtonContainer)}>
        <RightButton
          buttonObject={cardObject.button}
          buttonWidth={'13.7vw'}
          buttonHeight={'auto'}
        />
      </div>
    </div>
  )
}

CardType4.propTypes = {
  cardObject: PropTypes.object,
  visibility: PropTypes.bool,
}

const styles = StyleSheet.create({
  container: {
    // height: '100vh',
    padding: '1.5vw',
  },
  brandingContainer: {
    height: '12vh',
  },
  headerWithDescriptionContainer: {
    // height: '48vh',
    fontSize: '28px',
    lineHeight: '32.2px',
    '@media(max-width:800px)': {
      fontSize: '20px',
      lineHeight: '20px',
    }
  },
  rightButtonContainer: {
    height: '20vh',
    position: 'relative',
  },
  scrollCard: {
    overflowY: 'auto',
    pointerEvents: 'auto',
    height: '62vh',
    '::-webkit-scrollbar': {
      width: '0.4rem',
      margin: '4.8vh',
      background: 'rgba(61, 61, 61, 0.8)',
      borderRadius: '10px',
    },
    '::-webkit-scrollbar-thumb': {
      background: 'rgba(242, 242, 242, 0.15)',
      borderRadius: '20px',
      height: '2.4vh',
    },
    '@media(max-width:800px)': {
      height: '65vh'
    }
  }
})