import { useAnimations, useGLTF, Html } from '@react-three/drei'
// import { useAppState } from '../stores'
import React, { useEffect, useMemo } from 'react'
import { Skybox } from '../core/3d/Skybox'
import PropTypes, { string } from 'prop-types'
import { useAppState, useCustomCamera, useLoadingManager } from '../stores'
import { useThree } from '@react-three/fiber'
import { ModelAnimations } from './ModelAnimations'
import { SAOPass } from 'three/examples/jsm/postprocessing/SAOPass'
import { Lights } from '../core/3d/Lights'
import CustomSAO from '../../../context/CustomSAO'
import { Box3 } from 'three'
import * as THREE from 'three'
import Text, { BlueBoardText, Text2, Text3, Text4, Text5, Text6, ValuechainHeading } from './Text'
import PsurTextTop, {
  PsurTextBottom1,
  PsurTextBottom2,
  PsurTextLeft,
  PsurTextRight,
  PsurTextStart,
  PsurTextEnd,
} from './PsurText'
function playAnimationForward(anim) {
  if (!anim) return

  anim.loop = THREE.LoopRepeat
  anim.timeScale = 1
  anim.clampWhenFinished = true
  anim.paused = false
  anim.play()
}

export const BaseExtras = () => {
  const object = useGLTF('./glb/Extended.glb')
  const cars = useGLTF('./glb/City_VehicleAnimations.glb')
  const { actions } = useAnimations(cars.animations, cars.scene)
  useEffect(() => {
    Object.keys(actions).forEach((key) => {
      playAnimationForward(actions[key])
    })
  }, [])
  return (
    <>
      <primitive object={object.scene} />
      <primitive object={cars.scene} />
    </>
  )
}
export const ValueChain = () => {
  const { scene } = useThree()
  // useEffect(() => {
  //   scene.traverse((obj) => {
  //     obj.frustumCulled = false
  //   })
  // }, [])
  return (
    <>
      <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, -5, 0]} receiveShadow>
        <planeBufferGeometry args={[300, 300]} />
        <meshBasicMaterial color="#BDDEEF" receiveShadow />
      </mesh>
      <group position={[2.5, -0.1, 2.8]} rotation={[-Math.PI / 2, 0, 0]}>
        <Html zIndexRange={[0, 1]} transform>
          <ValuechainHeading />
        </Html>
      </group>
      <group position={[-8, -0.1, -4.3]} rotation={[-Math.PI / 2, 0, 0]} scale={[2.1, 2.1, 2.1]}>
        <Html zIndexRange={[0, 1]} transform>
          <Text />
        </Html>
      </group>
      <group position={[-1, -0.1, -4.3]} rotation={[-Math.PI / 2, 0, 0]} scale={[2.2, 2.2, 2.2]}>
        <Html zIndexRange={[0, 1]} transform>
          <Text2 transform />
        </Html>
      </group>
      <group position={[5.7, -0.15, -4]} rotation={[-Math.PI / 2, 0, 0]} scale={[2.3, 2.3, 2.3]}>
        <Html zIndexRange={[0, 1]} transform>
          <Text3 transform />
        </Html>
      </group>
      <group position={[11.3, -0.2, -4]} rotation={[-Math.PI / 2, 0, 0]} scale={[2.3, 2.3, 2.3]}>
        <Html zIndexRange={[0, 1]} transform>
          <Text4 />
        </Html>
      </group>
      <group position={[18.6, -0.25, -4.1]} rotation={[-Math.PI / 2, 0, 0]} scale={[2.4, 2.4, 2.4]}>
        <Html zIndexRange={[0, 1]} transform>
          <Text5 />
        </Html>
      </group>
      <group position={[24.4, -0.25, -4.4]} rotation={[-Math.PI / 2, 0, 0]} scale={[2.5, 2.5, 2.5]}>
        <Html zIndexRange={[0, 1]} transform>
          <Text6 />
        </Html>
      </group>
      <group position={[18.6, 0, 4.7]} rotation={[-Math.PI / 2, 0, 0]} scale={[2.1, 2.1, 2.1]}>
        <Html zIndexRange={[0, 1]} transform>
          <BlueBoardText />
        </Html>
      </group>
    </>
  )
}

export const PSURExtras = () => {
  return (
    <>
      <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, -5, 0]} receiveShadow>
        <planeBufferGeometry args={[300, 300]} />
        <meshBasicMaterial color="#BDDEEF" receiveShadow />
      </mesh>
      <mesh
        rotation={[-Math.PI / 2, 0, 0]}
        // position={[-150, 0, -150]}
        receiveShadow
      >
        <planeBufferGeometry args={[300, 300]} />
        <meshBasicMaterial color="#BDDEEF" receiveShadow />
      </mesh>
      {/* <group position={[-3.8, 0, -3.5]} rotation={[-Math.PI / 2, 0, 0]} scale={[2.1, 2.1, 2.1]}>
        <Html zIndexRange={[0, 1]} transform>
          <PsurTextStart />
        </Html>
      </group> */}
      {/* <group position={[36.7, 0, -2.8]} rotation={[-Math.PI / 2, 0, 0]} scale={[2.1, 2.1, 2.1]}>
        <Html zIndexRange={[0, 1]} transform>
          <PsurTextEnd />
        </Html>
      </group> */}
      <group position={[22, 0, -3.6]} rotation={[-Math.PI / 2, 0, 0]} scale={[2.1, 2.1, 2.1]}>
        <Html zIndexRange={[0, 1]} transform>
          <PsurTextTop />
        </Html>
      </group>
      <group position={[6.4, 0, 2.1]} rotation={[-Math.PI / 2, 0, 0]} scale={[2.1, 2.1, 2.1]}>
        <Html zIndexRange={[0, 1]} transform>
          <PsurTextLeft />
        </Html>
      </group>
      <group position={[29.8, 0, 8]} rotation={[-Math.PI / 2, 0, 0]} scale={[2.1, 2.1, 2.1]}>
        <Html zIndexRange={[0, 1]} transform>
          <PsurTextBottom1 />
        </Html>
      </group>
      <group position={[27.5, 0, 13.5]} rotation={[-Math.PI / 2, 0, 0]} scale={[2.1, 2.1, 2.1]}>
        <Html zIndexRange={[0, 1]} transform>
          <PsurTextBottom2 />
        </Html>
      </group>
      <group position={[32.5, 0, 2.1]} rotation={[-Math.PI / 2, 0, 0]} scale={[2.1, 2.1, 2.1]}>
        <Html zIndexRange={[0, 1]} transform>
          <PsurTextRight />
        </Html>
      </group>
    </>
  )
}
export const Room = React.memo(({ setActions, name, ...props }) => {
  // const { location, allRooms, hotspot } = useAppState()
  const object = useGLTF(props.url.split('\\').join('/'))
  const copiedScene = useMemo(() => {
    object.scene.traverse((node) => {
      node.frustumCulled = false
    })
    return object.scene.clone()
  }, [object])
  const loader = useLoadingManager()
  const { setObjectBounds } = useCustomCamera.getState()
  const { gl } = useThree()
  const { actions } = useAnimations(object.animations, object.scene)
  // const { currentContent } = useAppState()
  useEffect(() => {
    setTimeout(() => {
      loader.setFadeIn(false)
      loader.reportDone(props.name)
    }, [400])
  }, [])

  useEffect(() => {
    if (name === 'psur') {
    }
    setTimeout(() => (gl.shadowMap.autoUpdate = false), [2000])
    object.scene?.traverse((node) => {
      // node.frustumCulled = false
      setShadow(node)
    })
    setActions(actions)
    if (object) {
      const bounds = object.scene
      var bbox = new Box3().setFromObject(object.scene)
      setObjectBounds(bbox)
    }
    return () => (gl.shadowMap.autoUpdate = true)
  }, [props.url])
  const setShadow = (mesh) => {
    if (mesh.type == 'Mesh') {
      mesh.castShadow = true
      mesh.receiveShadow = true
      if (
        (name === 'psur' && mesh.material?.name === 'Glass.001') ||
        mesh.material?.name === 'Glass'
      ) {
        mesh.material.transparent = true
        mesh.material.alpha = 0.3
        mesh.material.opacity = 0.3
      }
    }
    // if (mesh.children){mesh.children.forEach(child=>setShadow(child))}
  }
  return (
    <>
      {/* <Skybox /> */}
      {props.shouldRender && !props.videoPlaying && <primitive object={copiedScene} />}
      <Lights roomName={name} />
      {(name === 'psur' || name === 'valuechain') && <CustomSAO roomName={name} />}
      {/* {actions && <ModelAnimations actions={actions} />} */}
      {name === 'base' && <BaseExtras />}
      {name === 'psur' && <PSURExtras />}
      {name === 'valuechain' && <ValueChain />}
    </>
  )
})

Room.propTypes = {
  url: PropTypes.String,
}
