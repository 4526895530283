import { Box } from '@react-three/drei'
import { Suspense } from 'react'
import { Camera } from '../core/3d/Camera'
// import { Camera } from '../core/3d/Camera'
import { MainScene } from './MainScene'
import { Sky } from './Sky'
export const Environment = () => {
  return (
    <>
      <Suspense fallback={<></>}>
        <MainScene />
        <Sky />
      </Suspense>
      {/* <Camera /> */}
      {/* <Box /> */}
    </>
  )
}
